import { ChangeDetectionStrategy, Component } from '@angular/core';
import { delay } from 'rxjs/operators';
import { LoadingService } from 'src/app/core/services/loading.service';

@Component({
  selector: 'app-loading-bar',
  templateUrl: './loading-bar.component.html',
  styleUrls: ['./loading-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingBarComponent {

  // delay these by 0 to prevent some ExpressionChanged errors
  // when used in mat-dialog components with animations
  loadingBar$ = this.loadingService.loadingBar$.pipe(delay(0));
  progress$ = this.loadingService.progress$.pipe(delay(0));

  constructor(
    private loadingService: LoadingService,
  ) { }

}
