import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { LoadingService } from '../../../../../core/services/loading.service';
import { MetaResources } from '../../../../interfaces/access.meta.interface';
import { AccessResource } from '../../../../interfaces/access.resource.class';
import { NodeSelectorComponent, NodeSelectorComponentResponse } from '../../node-selector/node-selector.component';

@Component({
  selector: 'app-resource',
  templateUrl: './resource.component.html',
  styleUrls: ['./resource.component.scss']
})
export class ResourceComponent implements OnInit {

  @Input() resource: AccessResource;
  @Input() meta: MetaResources;

  @Output() changes = new EventEmitter();
  editing = false;
  _resource: string;
  _parameter: string | string[];
  $selectedParameter = new ReplaySubject<string | string[]>(1);
  selectedParameter$ = this.$selectedParameter.pipe(startWith('*'));
  arguments$ = this.selectedParameter$.pipe(
    map(parameter =>
      Array.isArray(parameter) || parameter === '*' || !this.resource.meta.arguments || !this.resource.meta.arguments[parameter]
        ? ['*']
        : this.resource.meta.arguments[parameter]
    )
  );
  _argument: string | string[];
  preview: string;

  constructor(
    public loadingService: LoadingService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.updatePreview();
    this._resource = this.resource.resource;
    this._parameter = this.resource.parameter;
    this.$selectedParameter.next(this._parameter);
    this._argument = this.resource.argument;
  }

  toggleEdit() {
    this.editing = !this.editing;
    this.updatePreview();
  }

  setResource(resource: string) {
    this.resource.resource = resource;
    this.updatePreview();
    this.changes.emit(this.resource);
  }

  setParameter(parameter: string | string[]) {
    this.resource.parameter = parameter;
    this.$selectedParameter.next(parameter);
    this.updatePreview();
    this.changes.emit(this.resource);
  }

  setArgument(argument: string | string[]) {
    // const type = this.resource.meta.argumentType;
    this.resource.argument = argument;
    this.updatePreview();
    this.changes.emit(this.resource);
  }

  updatePreview() {
    this.preview = this.resource.toString(8);
  }

  toggleNodeSelector(nodes: string | string[]) {
    console.log('node selection start', nodes);
    let dialogRef: MatDialogRef<NodeSelectorComponent> | null = this.dialog.open(NodeSelectorComponent, {
      data: { nodes: Array.isArray(nodes) ? nodes.map(n => parseInt(n, 10)) : parseInt(nodes, 10) },
      width: '75%',
      height: '90%'
    });
    dialogRef.afterClosed().subscribe((response: NodeSelectorComponentResponse) => {
      const { selection, ok } = response;
      if (ok && selection) {
        console.log('node selection end', selection);
        this.setParameter(selection.map(x => `${x}`));
      }
      dialogRef = null;
    });
  }
}
