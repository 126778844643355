import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MenuService } from 'src/app/core/services/menu.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css']
})

export class ToolbarComponent {
  public openedSideNav: boolean;

  constructor(public translate: TranslateService, private menuService: MenuService, public userService: UserService) {
    this.translate.setDefaultLang('sv');
  }

  public onToggleSideNav() {
    this.menuService.toggle();
  }
}
