import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-warning-dialog',
  templateUrl: './warningdialog.component.html',
})
export class WarningDialogComponent {
  params: {};
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public diaRef: MatDialogRef<WarningDialogComponent>,
    // private translate: TranslateService
  ) {  }

  return(data: boolean): void {
    this.diaRef.close(data);
  }
}
