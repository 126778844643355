import { HttpErrorResponse } from '@angular/common/http';
import { Apollo, gql } from 'apollo-angular';
import { Observable, of, ReplaySubject, throwError } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { IGraphQLRequestOptions, IGraphQLResponse, IIdGraphQLRequestOptions } from '../interfaces/data.service.interface';
import { NotificationService } from '../services/notification.service';
import { AbstractDataService } from './abstract.data.service';

interface Response<S> {
  [key: string]: S; // can we type this key? some kind of "keyof Entities" from spec service?
}

export abstract class GraphQLService extends AbstractDataService {

  protected readonly $errors = new ReplaySubject<string>(1);
  public readonly errors$ = this.$errors.asObservable();

  constructor(
    protected apollo: Apollo,
    protected notificiations: NotificationService,
    protected resource = '/'
  ) {
    super();
  }

  protected errorHandler(error: HttpErrorResponse | Error) {
    if (error instanceof Error) {
      // these errors are generated in RestService
      this.$errors.next(`${error.name}: ${error.message}`);
      this.notificiations.error(`${error.name}: ${error.message}`);
      return throwError(`GraphQLService: Error occured: ${error.message}`);
    } else if (error instanceof HttpErrorResponse && error.error instanceof ErrorEvent) {
      // ErrorEvents are generated by the browser (network errors, etc)
      this.$errors.next(`${error.error.type}: ${error.error.message}`);
      this.notificiations.error(`${error.error.type}: ${error.error.message}`);
    } else {
      // HttpErrorResponse are from the server (500 internal server error, 404 not found, etc)
      this.$errors.next(`${error.status} ${error.statusText}: ${error.url}`);
      this.notificiations.http(this.resource, error);
    }
    console.error(`GraphQLService: Error on ${this.resource}: ${error.status} ${error.statusText} (${error.url})`);
    return of([]);
    // return throwError(`CachedRestService: Error on ${this.resource}: ${error.status} ${error.statusText} (${error.url})`);
  }

  /**
   * Fetch data from the API
   * @param options IGraphQLRequestOptions with request options
   * @param forceRefresh if true: bypass cache to ensure data is fetched from the server
   */
  protected requestGetAll<S>({ graphql, ids = [] }: IGraphQLRequestOptions, forceRefresh = false) {
    const { queries: { getAll }, collection } = graphql;
    return this.apollo.query<Response<S[]>>({
      query: gql(`${getAll}`),
      variables: {
        ids
      },
      // fetchPolicy: forceRefresh ? 'network-only' : 'cache-first'
      // TODO: skip cache?
      fetchPolicy: 'network-only'
    })
      .pipe(
        // tap(() => console.log('GraphQLService: GetAll %s data updated', collection)),
        tap(({ errors }) => errors && console.error('GraphQLService.getAll error:', errors)), // TODO: connect to notifications service
        map(({ data }) => data && data[collection] || [])
      );
  }

  protected requestGet<T>({ id, graphql: { queries: { get }, name, variable } }: IIdGraphQLRequestOptions) {
    const body: any = {
      query: gql(get),
      variables: {
        id
      },
      fetchPolicy: 'network-only'
    };
    return this.apollo.query<IGraphQLResponse<T>>(body)
      .pipe(
        tap(() => console.log('GraphQLService: Get %s data updated', name)),
        tap(({ errors }) => errors && console.error('GraphQLService.get error:', errors)), // TODO: connect to notifications service
        map(({ data }) => {
          // console.log('watch get from service items:', data);
          return data && data[variable];
        })
      );
  }

  protected requestCreate<T, ReturnType = T>(entity: T, { graphql: { queries: { post }, name } }: IGraphQLRequestOptions) {
    // console.log('entity', JSON.stringify(entity, null, 2));
    const now = Date.now();
    return this.apollo.mutate<Record<string, ReturnType>>({
      mutation: gql(post),
      variables: {
        data: entity
      }
    })
      .pipe(
        tap(result => console.log('GraphQLService: Create %s ms', Date.now() - now, result)),
        map(({ data }) => data && `create${name}` in data ? data[`create${name}`] : {} as ReturnType)
      );
  }

  protected requestUpdate<T>(entity: T, options: IIdGraphQLRequestOptions) {
    return this.requestPatch<T>(entity, options);
  }

  protected requestPatch<T, ReturnType = T>(partial: Partial<T>, { id, graphql: { queries: { patch }, name } }: IIdGraphQLRequestOptions): Observable<ReturnType> {
    const now = Date.now();
    return this.apollo.mutate<Record<string, ReturnType>>({
      mutation: gql(patch),
      variables: {
        id,
        fields: { set: partial }
      }
    })
      .pipe(
        tap(result => console.log('GraphQLService: Patch %s %s (%s ms)', name, id, Date.now() - now, result)),
        map(({ data }) => data && `patch${name}` in data ? data[`patch${name}`] : {} as ReturnType)
      );
  }

  protected requestPatchMany<T, ReturnType = T>(partial: Partial<T>, { ids = [], graphql: { queries: { patchMany }, name } }: IGraphQLRequestOptions): Observable<ReturnType[]> {
    const now = Date.now();
    return this.apollo.mutate<Record<string, ReturnType[]>>({ // TODO: newer Typescript can use Template Literal Types to type this as `PatchMany${name}`
      mutation: gql(patchMany),
      variables: {
        ids,
        fields: { set: partial }
      }
    })
      .pipe(
        tap(result => console.log('GraphQLService: PatchMany %s (%s ms)', name, Date.now() - now, ids, result)),
        map(({ data }) => {
          const mutation = `patchMany${name}`;
          const items = data && mutation in data ? data[mutation] : [];
          return Array.isArray(items) ? items : [items]; // GraphQL returns non-array if there's only 1 item
        })
      );
  }

  protected requestDelete({ id, graphql: { queries: { remove }, variable } }: IIdGraphQLRequestOptions) {
    const now = Date.now();
    return this.apollo.mutate({
      mutation: gql(remove),
      variables: {
        id
      },
    })
      .pipe(
        tap(result => console.log('GraphQLService: Delete %s ms', Date.now() - now, result)),
        map(({ data }) => data as boolean)
      );
  }

  protected requestDeleteMany({ ids, graphql: { queries: { removeMany }, variable } }: IGraphQLRequestOptions) {
    const now = Date.now();
    return this.apollo.mutate({
      mutation: gql(removeMany),
      variables: {
        ids
      },
    })
      .pipe(
        tap(result => console.log('GraphQLService: DeleteMany %s ms', Date.now() - now, result)),
        map(({ data }) => data as boolean)
      );
  }

}
