import { MetaAccessType, MetaResource, MetaResources } from './access.meta.interface';

const setRegExp = /^\[([\w_\.|]+)\]$/;
const arrayRegExp = /^@?\+?\(([\w_\.|]+)\)$/;

export class AccessResource {
  meta: MetaResource;
  identifier = 'acarn';
  system: string;
  resource: string;
  resources: string[];
  resourceWildcard: boolean;
  parameter: string | string[];
  parameters: Array<string | number>;
  parameterWildcard: boolean;
  argument: string | string[];
  arguments: string[];

  constructor(private raw?: string) {
    // <identifier>:<system>:<name>:<parameter>:<arguments>
    // acarn:acaconfig:costcenter
    // acarn:acaconfig:node:@(1|2)
    // acarn:acaconfig:field:confidentiality:[1234]
    this.update(raw);
  }

  static parseParameter(parameter: string | string[], meta: MetaResource) {
    if (!parameter) { return ''; }
    if (Array.isArray(parameter)) { return parameter; }

    if (parameter.match(setRegExp)) {
      console.log('parseParam', parameter.match(setRegExp), parameter.replace(setRegExp, '$1').split(/\B/));
    }

    const output = meta.parameterType?.multiple
      ? parameter.match(setRegExp)
        // "[1234]" -> ["1", "2", "3", "4"]
        ? parameter.replace(setRegExp, '$1').split(/\B/)
        : parameter.match(arrayRegExp)
          // "@(id1,id2)" -> ["id1", "id2"]
          ? parameter.replace(arrayRegExp, '$1').split(/\|/)
          : [parameter]
      : parameter;
    console.log('parseParam', output, parameter, meta.parameterType);
    return output;
  }

  static parseArgument(argument: string | string[], meta: MetaResource) {
    if (!argument) { return ''; }
    if (Array.isArray(argument)) { return argument; }

    const output = meta.argumentType?.multiple
      ? argument.match(setRegExp)
        // "[1234]" -> ["1", "2", "3", "4"]
        ? argument.replace(setRegExp, '$1').split(/\B/)
        : argument.match(arrayRegExp)
          // "@(id1,id2)" -> ["id1", "id2"]
          ? argument.replace(arrayRegExp, '$1').split(/\|/)
          : [argument]
      : argument;
    console.log('parseArg', output, argument, meta.argumentType);
    return output;
  }

  public setMeta(meta: MetaResources | MetaResource): AccessResource {
    if (Array.isArray(meta)) {
      const currentMeta = meta && Array.isArray(meta) ? meta.find(m => m.resource === this.resource) : meta;
      if (!currentMeta) {
        throw new Error(`Invalid AccessResource string: ${this.raw} (unknown resource type '${this.resource}', verify support in external system access meta endpoint)`);
      }
      this.meta = currentMeta;
    } else {
      this.meta = meta;
    }

    this.system = this.meta.system;
    this.resource = this.meta.resource;
    // this.subject = this.meta.subjects.includes(this.subject) ? this.subject : this.meta.subject;

    this.reparse();

    return this;
  }

  public reparse() {
    this.parameter = AccessResource.parseParameter(this.parameter, this.meta);
    this.argument = AccessResource.parseArgument(this.argument, this.meta);
  }

  public update(raw: string = 'acarn:acaconfig:*:*'): AccessResource {
    const [identifier, system, resource, parameter, argument] = raw.split(/:/);
    if (!identifier || identifier !== 'acarn') {
      throw new Error(`Invalid AccessResource string: ${raw} (invalid identifier, expected 'acarn')`);
    }
    if (!system) {
      throw new Error(`Invalid AccessResource string: ${raw} (expected 'system' in <identifier>:<system>:<resource>:<parameter>:<argument?>)`);
    }
    if (!resource) {
      throw new Error(`Invalid AccessResource string: ${raw} (expected 'resource' in <identifier>:<system>:<resource>:<parameter>:<argument?>)`);
    }
    // TODO: mandatory or nah?
    // if (!parameter) {
    //   throw new Error(`Invalid AccessResource string: ${raw} (expected 'parameter' in <identifier>:<system>:<resource>:<parameter>:<argument?>)`);
    // }
    this.identifier = identifier;
    this.system = system;
    this.resource = resource;
    this.parameter = parameter || '*';
    this.argument = argument || '*';
    return this;
  }

  private parameterToString(max = Infinity) {
    if (!this.parameter || this.meta.parameterType?.type === 'none') {
      return '';
    }
    return packResourceParameterValues(this.parameter, this.meta.parameterType, max);
  }

  private argumentToString(max = Infinity) {
    if (!this.argument || this.meta.argumentType?.type === 'none') {
      return '';
    }
    return packResourceArgumentValues(this.argument, this.meta.argumentType, max);
  }

  toString(max = Infinity) {
    const params = this.parameterToString(max);
    const argument = this.argumentToString(max);
    // console.log('this', this.argument, argument);
    return [this.identifier, this.system, this.resource, params, argument].filter(Boolean).join(':').replace(/\*:\*/g, '*');
  }

}

export function packResourceParameterValues(values: string | string[], meta: MetaAccessType, max = Infinity) {
  return _packValues(meta.multiple && values.includes('*') ? ['*'] : values, meta, max);
}

export function packResourceArgumentValues(values: string | string[], meta: MetaAccessType, max = Infinity) {
  return _packValues(meta.multiple && values.includes('*') ? ['*'] : values, meta, max);
}

function _packValues(values: string | string[], { multiple = false, type = 'string', wildcard = true }: MetaAccessType, max = Infinity) {
  const result = multiple
    ? Array.isArray(values)
      ? abbreviateArray(values, max, type)
      : wildcard
        ? '*'
        : ''
    : type === 'string' && values.length ? values : wildcard ? '*' : '';

  // console.log('_packValues', values, values.length, multiple, max, result);
  return result;
}

function abbreviateArray(values: string[], max = Infinity, type = 'string') {
  const value = abbreviator(values, max);
  return value.length > 0 && !value.includes('*')
    // encode all string values and numbers > 9 as an array @(abc,10,def) or single digit numbers as a set [123]. see
    ? type === 'number' && values.every(v => `${v}`.length === 1)
      ? `[${value.join('')}]`
      : `@(${value.join('|')})`
    : '*';
}

function abbreviator(values: string[], max = Infinity) {
  return values.length > max
    ? values.slice(0, Math.ceil(max)).concat(`...+${values.length - max}`)
    // ? values.slice(0, Math.ceil(max / 2)).concat(`(+${values.length - max})`, values.slice(-Math.floor(max / 2)))
    : values;
}
