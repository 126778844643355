
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import * as storage from '../classes/storage';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class SessionActiveGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService
  ) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<UrlTree | boolean> {
    if (!storage.sessionHasItem('tokens') && !state.url.endsWith('/login')) {
      console.log('SessionActiveGuard: Tokens missing, redirecting to login.');
      return this.router.parseUrl('/login');
    }
    this.userService.refresh();
    return true;
  }
}
