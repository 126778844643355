import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { CachedGraphQLService } from '../classes/cached.graphql.service';
import { Entity } from '../interfaces/entity.interface';
import { ID } from '../interfaces/model.interface';
import { INode } from '../interfaces/node.interface';
import { Specification } from '../interfaces/specification.interface';
import { NotificationService } from './notification.service';
import { SpecificationService } from './specification.service';

@Injectable({
  providedIn: 'root'
})
export class EntitiesService extends CachedGraphQLService<Entity> {

  constructor(
    protected apollo: Apollo,
    protected notificiations: NotificationService,
    protected specifications: SpecificationService,
  ) {
    super(apollo, notificiations, '/', (input: Entity) => new Entity().deserialize(input));
  }

  public getAllByType(type: string): Observable<Entity[]> {
    return this.specifications.get(type).pipe(
      switchMap((spec: Specification) => {
        return super.cachedPeekAll(spec);
      })
    );
  }

  public getAll({ graphql }: Specification, forceRefresh = false) {
    return super.cachedGetAll({ graphql }, forceRefresh)
      .pipe(
        tap(data => console.log('EntityService: New data for %s (%s items) (forced: %s).', graphql.name, data.filter(x => !!x).length, forceRefresh))
      )
      ;
  }

  public get({ graphql }: Specification, id: ID) {
    return super.cachedGet({ id, graphql })
      // .pipe(
      //   tap(data => console.log('EntityService: Get %s: %s', type, id, data))
      // )
      ;
  }

  public getMany({ graphql }: Specification, ids: ID[]) {
    return super.cachedGetAll({ ids, graphql })
      // .pipe(
      //   tap(data => console.log('EntityService: GetMany %s', type, ids, data))
      // )
      ;
  }

  public create({ graphql }: Specification, entity: Entity) {
    return super.cachedCreate(entity, { graphql })
      // .pipe(
      //   tap(data => console.log('EntityService: Created %s', type, data))
      // )
      ;
  }

  public remove({ graphql }: Specification, id: ID) {
    return super.cachedDelete({ id, graphql })
      // .pipe(
      //   tap(data => console.log('EntityService: Delete %s', type, data))
      // )
      ;
  }

  public removeMany({ graphql }: Specification, ids: ID[]) {
    return super.cachedDeleteMany({ ids, graphql })
      // .pipe(
      //   tap(data => console.log('EntityService: DeleteMany %s: %s', type, ids, data))
      // )
      ;
  }

  public patch({ name, graphql }: Specification, id: ID, partial: Partial<Entity>) {
    return super.cachedPatch(partial, { id, graphql })
      // .pipe(
      //   tap(data => console.log('EntityService: Patch %s: %s', name, id, data))
      // )
      ;
  }

  public patchMany({ name, graphql }: Specification, ids: ID[], partial: Partial<Entity>): Observable<Entity[]> {
    return super.cachedPatchMany(partial, { ids, graphql })
      // .pipe(
      //   tap(data => console.log('EntityService: PatchMany %s', name, ids, data))
      // )
      ;
  }

  public cacheIt(node: INode) {
    return super.cacheIt(node);
    // .pipe(
    //   tap(data => console.log('EntityService: Created %s', type, data))
    // );
  }

}
