import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, of } from 'rxjs';
import { catchError, map, pluck, shareReplay, tap } from 'rxjs/operators';
import { environment as env } from 'src/environments/environment';
import { AccessMetaData } from '../../shared/interfaces/access.meta.interface';
import { INode } from '../interfaces/node.interface';

@Injectable({
  providedIn: 'root'
})
export class ExternalSystemsService {

  public readonly meta$ = this.getMeta().pipe(
    tap(x => console.log('meta', x)),
    shareReplay(1)
  );

  public readonly metaActions$ = this.meta$.pipe(
    map(systems => systems.flatMap(system => system.actions)),
  );

  public readonly metaResources$ = this.meta$.pipe(
    map(systems => systems.flatMap(system => system.resources)),
  );

  public readonly organization$ = this.getOrganization().pipe(
    pluck('roots'),
    tap(x => console.log('organization', x)),
    // shareReplay(1),
  );

  constructor(private http: HttpClient) {
  }

  getOrganization() {
    return this.fetch<INode[]>('entities/v2/groups/tree?sparse=true', env.nodesPath);
  }

  getMeta() {
    return combineLatest(
      env.accessSystemPaths.map(path =>
        this.fetch<AccessMetaData>('meta/v2/resources', path)
          .pipe(catchError(err => of(undefined))) // hide unaccessible systems
      )
    ).pipe(
      map(systems => systems.filter(system => !!system) as AccessMetaData[]),
    );
  }

  private fetch<T>(resource: string, path = env.apiPath, query?: string) {
    return this.http.get<T>(`${path}/${resource}${query ? '?' + query : ''}`);
  }
}
