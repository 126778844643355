import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Specification } from '../../../core/interfaces/specification.interface';
import { ExternalSystemsService } from '../../../core/services/externalSystems.service';
import { FormService } from '../../services/form.service';

@Component({
  selector: 'app-statement-editor',
  templateUrl: './statement-editor.component.html',
  styleUrls: ['./statement-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatementEditorComponent implements OnInit {

  @Input() public form: FormGroup;
  @Input() public spec: Specification;

  constructor(
    public externalSystemsService: ExternalSystemsService,
    public formService: FormService
  ) { }

  ngOnInit() {
  }

  public getControl(key: string) {
    return this.form.get(key) as FormControl;
  }

  updateActions(actions: string[]) {
    this.form.patchValue({ actions });
  }

  updateResources(resources: string[]) {
    this.form.patchValue({ resources });
  }

}
