import { Injectable } from '@angular/core';
import { of, Subject } from 'rxjs';
import { delay, map, scan, shareReplay, startWith, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private $inflight = new Subject<number>();

  public readonly inflight$ = this.$inflight
    .pipe(
      scan((inflights, delta) => Math.max(inflights + delta, 0), 0),
      delay(0),
      shareReplay(1),
    );

  public readonly progress$ = this.$inflight
    .pipe(
      scan((progress, delta) => {
        if (progress.max === progress.current) {
          progress = { current: 0, max: 0 };
        }
        const current = Math.max(progress.current, progress.current - delta);
        const max = Math.max(progress.max, progress.max + delta);
        return { current, max };
      }, { current: 0, max: 1 }),
      // format as percent, 1-100
      map(progress => progress.max === 0 ? 100 : progress.current / progress.max * 100),
      // reset to 0 after a while to prevent flickers on next bar
      switchMap(progress => progress === 100 ? of(0).pipe(delay(2000), startWith(progress)) : of(progress)),
      delay(0),
      shareReplay(1),
    );

  public readonly loading$ = this.inflight$
    .pipe(
      map(x => x > 0),
      delay(0),
      shareReplay(1)
    );

  public readonly loadingBar$ = this.loading$
    .pipe(
      // delay when done to let loading bar animations finish
      switchMap(loading => loading ? of(loading) : of(loading).pipe(delay(1000))),
      delay(0),
      shareReplay(1)
    );

  public readonly ready$ = this.inflight$.pipe(map(inflight => inflight === 0), shareReplay(1));

  constructor() { }

  /**
   * Increase the number of active requests by 1.
   */
  public add(amount = 1): void {
    this.$inflight.next(amount);
  }

  /**
   * Decreases the number of active requests by 1.
   */
  public done(amount = 1): void {
    this.$inflight.next(-amount);
  }

}
