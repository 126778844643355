import { MetaAction, MetaActions } from './access.meta.interface';

export class AccessAction {
  meta: MetaAction;
  system: string;
  action: string;
  subject: string;
  subjects: string[];
  arg1?: string;
  arg2?: string;
  allowWildcard: boolean;

  constructor(public raw?: string) {
    this.update(raw);
  }

  static fromMeta(meta: MetaAction) {
    return `${meta.system}:${meta.action}`;
  }

  public setMeta(meta: MetaActions | MetaAction): AccessAction {
    if (Array.isArray(meta)) {
      const currentMeta = meta && Array.isArray(meta) ? meta.find(m => m.action === this.action) : meta;
      if (!currentMeta) {
        throw new Error(`Invalid AccessAction string: ${this.raw} (unknown action type '${this.action}', verify support in external system's access meta endpoint)`);
      }
      this.meta = currentMeta;
    } else {
      this.meta = meta;
    }

    this.system = this.meta.system;
    this.action = this.meta.action;
    this.subject = this.meta.subjects.includes(this.subject) ? this.subject : this.meta.subject;

    return this;
  }

  public update(raw: string = '*:*'): AccessAction {

    raw = raw || '*:*';
    // acaconfig:query:costcenter
    const systemRegex = /^([\w*]+):.*$/;
    const system = raw.match(systemRegex);
    if (!system || !system[1]) {
      throw new Error(`Invalid AccessAction string: ${raw} (expected system parameter)`);
    }
    this.system = system[1];

    const actionRegex = /^[\w*]+:([\w*]+):?.*$/;
    const action = raw.match(actionRegex);
    if (!system || !action || !system[1] || !action[1]) {
      throw new Error(`Invalid AccessAction string: ${raw} (expected action parameter)`);
    }
    this.action = action[1];

    const subjectRegex = /^[\w*]+:[\w*]+:([\w*]+):?.*$/;
    const subject = raw.match(subjectRegex);
    this.subject = subject ? subject[1] : '*';

    return this;
  }

  toString() {
    return [this.system, this.action, this.subject].join(':');
  }
}
