import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

interface FieldPolicy {
  type: string;
  required: boolean;
  disabled: boolean;
  label: string;
  array?: boolean;
  multiple?: boolean;
}

export const validator = (policy: FieldPolicy): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const { type, required, disabled, label, array, multiple } = policy;
    const { value } = control;
    const errors: ValidationErrors = {};

    if (disabled) {
      return null
    }
    if (required) {
      if ([null, undefined, ''].includes(value)) {
        errors.required = 'validation_required';
      }
      if (array && !value?.length) {
        errors.required = 'validation_at_least_one';
      }
    }
    return Object.keys(errors).length > 0 ? errors : null;
  };
};
