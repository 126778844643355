import { ID } from './model.interface';

export interface Column {
  name: string;
  key: string;
  label: string;
  checked: boolean;
  filterable: boolean;
}

export interface GraphQLSpecification {
  name: string;
  namePlural: string;
  variable: string;
  entity: string; // TODO: need good names for entity and entity in plural
  collection: string; // TODO: deprecate?
  mutations: GraphQLQueries;
  queries: GraphQLQueries;
}

export interface GraphQLQueries {
  post: string;
  put: string;
  patch: string;
  patchMany: string;
  remove: string;
  removeMany: string;
  getAll: string;
  get: string;
  getMany: string;
}

export interface Meta {
  type: string;
  removable?: boolean;
  creatable?: boolean;
  unredactable?: boolean;
  presentation?: string;
}

export interface Parents {
  type: string;
  array: boolean;
  default: any[];
}

export class Specification {
  id: ID;
  fields: Fields;
  graphql: GraphQLSpecification;
  meta: Meta;
  name: string;
  parents: Parents;
  layout: Layout[];
  deserialize(input: any): this {
    return Object.assign(this, input);
  }
}

export type Specifications = Specification[];

export interface Fields {
  [name: string]: FieldSpecification;
}

export interface FieldSpecification {
  type: 'string' | 'boolean' | 'number' | 'select' | 'object' | 'reference' | string;
  fields: Fields;
  array: boolean;
  primary: boolean; // Primary values show up in the table view of these entities.
  hintAs: string; // Show the value of another field as an hint in square brackets. Example: Group name [Group type]
  displayAs: string; // The key to display in an object. Example: 'name' in { name: 'My Value', value: 'foo' }
  valueAs: string; // The key to use as value in an object. Example: 'value' in { name: 'My Value', value: 'foo' }
  system: boolean; // System value. Not modifiable by users.
  hidden: boolean; // Don't show these.
  default: any; // The default value of this field.
  required: boolean; // The field is mandatory.
  readonly: boolean; // The field cannot be modified.
  options: any[]; // For available options for select type values.
  subType?: string; // Deprecated
  skippable?: boolean; // Not included in "sparse" data when fetching all entities. Used to exclude data to reduce response size.
  filterable?: boolean; // Show GUI controls to filter on this field.
  ref: FieldReferenceSpecification; // The value is a reference to another Entity
  sortBy: string; // Key to use to sort object arrays
  meta?: {
    presentation: 'grid' | string; // Grid is used by AcaAccess.
    order?: string[];
    useFormArray?: boolean; // Allow Grid components to select the type of Form to use
  };
  validation?: { // WIP.
    pattern?: string; // the string value must match this regex.
    ref?: {
      field: string; // ref is the path to another field containing an array of values, and this field has to be one of those values
      constraint: "alwaysIncludes" | "in";
    }
  }
}

export interface FieldReferenceSpecification {
  to: string;
  collection: string;
  groupBy: string;
  compare: string;
  compareTo: string;
  id: string;
  defaults: string;
}

export interface Layout {
  key: string;
  fields: string[][];
}
