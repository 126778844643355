import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { LoadingService } from 'src/app/core/services/loading.service';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class LoadingSpinnerComponent implements OnInit {

  @Input() diameter = 42;
  @Input() permanent = false;

  constructor(
    public loadingService: LoadingService,
  ) { }

  ngOnInit() {

  }

}
