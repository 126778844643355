import { Observable } from 'rxjs';
import { ID, Model } from './model.interface';
import { Specification } from './specification.interface';

export class Entity implements Model {
  id: ID;
  name: string;
  parents: Group[];
  [key: string]: any;
  deserialize(input: any): this {
    return Object.assign(this, input);
  }
}

export interface Group {
  id: ID;
  name: string;
  type: string;
  description: string;
  parents: Group[];
  _createdAt: Date;
  _modifiedAt: Date;
}

export interface EntityEditorDialogData {
  entity$: Observable<Partial<Entity>>; // the (sometimes partial) data to save
  entities?: Partial<Entity>[]; // the (sometimes partial) data to save
  specification: Specification;
  id?: number; // id to update for single entity edit/patch
  ids?: number[]; // ids to use for multipatching
  multiple?: boolean; // dialog should be in partial input mode and patch all ids with the data
  create?: boolean; // dialog should require all fields and then POST the data to create a new entity
}
