import { registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import localeSwedish from '@angular/common/locales/sv';
import { NgModule } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { CoreModule } from './core/core.module';
import { PaginatorIntl } from './core/services/paginatorIntl.service';
import { MaterialModule } from './shared/material.module';
import { SharedModule } from './shared/shared.module';

registerLocaleData(localeSwedish);

export function translateLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    CoreModule,
    RouterModule,
    SharedModule,
    AppRoutingModule,
    MaterialModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  exports: [
    BrowserModule,
  ],
  providers: [
    {
      provide: MatPaginatorIntl,
      deps: [TranslateService],
      useFactory: (translateService: TranslateService) =>
        new PaginatorIntl(translateService).getPaginatorIntl()
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
