import { Observable } from 'rxjs';
import { IIdRequestOptions, IRequestOptions } from '../interfaces/data.service.interface';

export abstract class AbstractDataService {
  protected abstract requestGetAll<S>(options: IRequestOptions): Observable<S[]>;
  protected abstract requestGet<T>(options: IIdRequestOptions): Observable<T>;
  protected abstract requestCreate<T>(entity: T, options: IRequestOptions): Observable<T>;
  protected abstract requestUpdate<T>(entity: T, options: IRequestOptions): Observable<T>;
  protected abstract requestPatch<T>(partial: Partial<T>, options: IRequestOptions): Observable<T>;
  protected abstract requestDelete(options: IIdRequestOptions): Observable<boolean>;
}
