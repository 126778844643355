import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { InMemoryCache } from '@apollo/client/cache';
import { setContext } from '@apollo/client/link/context';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { CookieService } from 'ngx-cookie-service';
import { environment as env } from 'src/environments/environment';
import * as storage from './classes/storage';
import { TokenInterceptor } from './interceptor/token.interceptor';
import { LoadingService } from './services/loading.service';
import { UserService } from './services/user.service';

const authLink = setContext((_, { headers }) => {
  const token = storage.sessionGetItem('tokens');
  return { headers: token ? { ...headers, Authorization: JSON.parse(token).id } : headers };
});

@NgModule({
  imports: [
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule,
  ],
  exports: [
    BrowserAnimationsModule,
    HttpClientModule,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'sv' },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true, deps: [LoadingService, UserService, HttpClient] },
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink) => {
        return {
          link: authLink.concat(httpLink.create({ uri: `${env.entitiesPath}/graphql` })),
          cache: new InMemoryCache({
            addTypename: false,
          }),
          defaultOptions: {
            watchQuery: {
              fetchPolicy: 'no-cache',
              errorPolicy: 'ignore',
            },
            query: {
              fetchPolicy: 'no-cache',
              errorPolicy: 'all',
            },
          }
        };
      },
      deps: [HttpLink],
    },
    CookieService,
    UserService
  ]
})

export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(`CoreModule has already been loaded. Import Core modules in the AppModule only.`);
    }
  }
}
