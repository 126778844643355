import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { map, shareReplay, startWith } from 'rxjs/operators';
import { Fields, FieldSpecification, Specification } from 'src/app/core/interfaces/specification.interface';
import { Entity } from '../../../core/interfaces/entity.interface';
import { LoadingService } from '../../../core/services/loading.service';

@Component({
  selector: 'app-entity-editor',
  templateUrl: './entity-editor.component.html',
  styleUrls: ['./entity-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityEditorComponent implements OnInit {

  @Input() public form: FormGroup;
  @Input() public spec: Specification;
  @Input() public multiple = false;

  objectKeys = Object.keys;

  constructor(
    public readonly translate: TranslateService,
    public loadingService: LoadingService,
  ) { }

  ngOnInit() { }

  getFormGroup(path: string[], form: AbstractControl = this.form): FormGroup {
    const head = path.slice(0, -1);
    // nested object just has the top level in the path, meanings there's no head.
    // in those case we just send the top level form and let form-creator figure it out.
    return (form.get(head) || form) as FormGroup;
  }

  getFormValueObservable(path?: string): Observable<Entity[]> {
    const control = path ? this.getControl(path.split('.')) : undefined;
    const value = control?.value;
    return control
      ? control.valueChanges.pipe(
        map(value => Array.isArray(value) ? value : !!value ? [value] : []),
        startWith(value
          ? Array.isArray(value)
            ? value
            : [value]
          : []
        ),
        shareReplay(1)
      )
      : of([]);
  }

  getControl(path: string[], form: AbstractControl = this.form): AbstractControl {
    return form.get(path) as AbstractControl;
  }

  getFieldSpec([next, ...rest]: string[], fields: Fields = this.spec.fields): FieldSpecification {
    const field = fields && fields[next];
    if (field && rest.length > 0) {
      return this.getFieldSpec(rest, field.fields);
    }
    return field;
  }

  getKey(path: string[]): string {
    return path && path[path.length - 1];
  }

}

