import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MenuService } from './core/services/menu.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  private subscriptions: Map<string, Subscription> = new Map();

  pageType = 'footer';
  sideNavOpened: boolean;
  _username: string | undefined;

  constructor(
    private menuService: MenuService,
    private router: Router,
    public translate: TranslateService,
  ) {
    this.translate.setDefaultLang('sv');
    this.router.events
    .pipe(filter(evt => evt instanceof NavigationEnd))
    .subscribe((evt: NavigationEnd) => {
      this.pageType = evt.url === '/' || evt.url === '/dashboard' ? 'footer' : 'tabs';
    });
  }

  ngOnInit() {
    this.subscriptions.set('menuState', this.menuService.state$.subscribe(open => this.sideNavOpened = open));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  onSideNavClosed() {
    this.menuService.close();
  }
}
