import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SessionActiveGuard } from './core/guard/session-active.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
    canActivate: [SessionActiveGuard],
    pathMatch: 'full',
    data: { title: 'Login' }
  },
  {
    path: 'entities',
    loadChildren: () => import('./entities/entities.module').then(m => m.EntitiesModule),
    canActivate: [SessionActiveGuard],
    pathMatch: 'full',
    data: { title: 'Entities' }
  },
  {
    path: 'organization',
    loadChildren: () => import('./organization/organization.module').then(m => m.OrganizationModule),
    canActivate: [SessionActiveGuard],
    pathMatch: 'full',
    data: { title: 'Organization' }
  },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '/login',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
