import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { HttpNotification } from '../interfaces/notifications.interface';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private $http = new Subject<HttpNotification>();
  private $error = new Subject<string>();
  private $warn = new ReplaySubject<string>(1);
  private $info = new Subject<string>();

  public http$ = this.$http.asObservable();
  public error$ = this.$error.asObservable();
  public warn$ = this.$warn.asObservable();
  public info$ = this.$info.asObservable();

  constructor() { }

  public http(source: string, error: HttpErrorResponse) {
    this.$http.next([source, error]);
  }

  public warn(message: string) {
    this.$warn.next(message);
  }

  public error(message: string) {
    this.$error.next(message);
  }

  public info(message: string) {
    this.$info.next(message);
  }

}
