import { Apollo } from 'apollo-angular';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ICacheLayer } from '../interfaces/cache.layer.interface';
import { IGraphQLRequestOptions, IIdGraphQLRequestOptions } from '../interfaces/data.service.interface';
import { ID, Model } from '../interfaces/model.interface';
import { INode } from '../interfaces/node.interface';
import { GraphQLService } from './graphql.service';

type SpecType = string;

interface ItemResponse {
  // [mutation: string]: { // TODO: use new template types
    id: ID;
    _createdAt: Date;
    _modifiedAt: Date;
  // };
}
type ItemResponses = Record<string, ItemResponse>;

export abstract class CachedGraphQLService<Item extends Model> extends GraphQLService implements ICacheLayer<Item> {

  // record of all items from a type
  private items: Record<SpecType, Item[]> = {};
  protected readonly $items: Record<SpecType, ReplaySubject<Item[]>> = {};
  public readonly items$: Record<SpecType, Observable<Item[]>> = {};

  // individual items
  protected $item: Record<SpecType, Record<ID, ReplaySubject<Item>>> = {};
  protected readonly item$: Record<SpecType, Record<ID, Observable<Item>>> = {};

  constructor(
    protected apollo: Apollo,
    protected notificiations: NotificationService,
    protected resource: string,
    protected deserialize: (data: Item, index: number) => Item
  ) {
    super(apollo, notificiations, resource);
  }

  // helpers
  public cachedPeek(options: IIdGraphQLRequestOptions) {
    const { id, graphql: { variable } } = options;
    this.item$[variable] = this.item$[variable] || {};
    return this.item$[variable][id] || this.cachedGet(options);
  }

  public cachedPeekAll(options: IIdGraphQLRequestOptions) {
    const { graphql: { variable } } = options;

    // Using the cache here causes the entity editing dialog form fields to break when
    // you move between different tabs.
    // https://academedia.atlassian.net/jira/software/projects/AAA10/boards/171?selectedIssue=AAA10-15
    // return this.items$[variable] || this.cachedGetAll(options);

    return this.cachedGetAll(options);
  }

  // CRUD
  public cachedGetAll(options: IGraphQLRequestOptions, forceRefresh = false) {
    const { graphql: { variable } } = options;

    this.$item[variable] = this.$item[variable] || {};
    this.item$[variable] = this.item$[variable] || {};
    // structure example: this.$item['company'][27] = { name: 'company 27' }
    this.$items[variable] = this.$items[variable] || new ReplaySubject<Item[]>(1);
    this.items$[variable] = this.items$[variable] || this.$items[variable].asObservable();

    super.requestGetAll<Item>(options, forceRefresh)
      .pipe(map(items => items ? items.map(this.deserialize) : []))
      .subscribe(
        (data: Item[]) => {
          // place the items in a sparse array indexed by their ID, for O(1) lookups
          this.items[variable] = data.reduce((list, item) => {
            list[item.id] = item;
            return list;
          }, [] as Item[]);
          this._closeUnusued(variable);
          this.$items[variable].next(this.items[variable].filter(x => !!x));
        }
      );

    return this.items$[variable];
  }

  public cachedGet(options: IIdGraphQLRequestOptions) {

    const { id, graphql: { variable } } = options;
    this.items[variable] = this.items[variable] || [];
    this.$item[variable] = this.$item[variable] || { [id]: new ReplaySubject<Item>(1) };
    this.item$[variable] = this.item$[variable] || { [id]: this.$item[variable][id].asObservable() };
    this.$items[variable] = this.$items[variable] || new ReplaySubject<Item[]>(1);
    // structure example: this.$item['company'][27] = { name: 'company 27' }
    this.$item[variable][id] = this.$item[variable][id] || new ReplaySubject<Item>(1);
    this.item$[variable][id] = this.item$[variable][id] || this.$item[variable][id].asObservable();
    // optimistically start with the item from the main array
    const item = variable in this.items && id in this.items[variable] && this.items[variable][id];
    if (item) {
      this.$item[variable][id].next(this._unreference(item));
    }

    super.requestGet<Item>(options)
      .pipe(map(this.deserialize))
      .subscribe((data: Item) => {
        // console.log('watch get items:', data);
        const newItem = this._unreference(data);
        if (this._different(newItem, this.items[variable][id])) {
          this.items[variable][id] = this._unreference(data);
          this.$item[variable][id].next(this._unreference(data));
          this.$items[variable].next(Object.values(this.items[variable].filter(x => !!x)));
        }
      });
    return this.item$[variable][id];
  }

  public cachedCreate(item: Item, options: IGraphQLRequestOptions) {

    const { graphql: { variable } } = options;

    const subject = new ReplaySubject<Item>(1);
    const observable = subject.pipe(distinctUntilChanged());

    // optimistically add it
    const optimisticItem = { ...this.deserialize(item, Date.now()), id: -1 };
    this.$items[variable].next([optimisticItem, ...Object.values(this.items[variable].filter(x => !!x))]);
    subject.next(optimisticItem);

    super.requestCreate<Item, ItemResponse>(item, options)
      .subscribe((response: ItemResponse) => {
        if (response) {
          // update the optimistically added item in the main list and complete the creation
          Object.assign(optimisticItem, response);
          this.items[variable][response.id] = optimisticItem;
          this.$item[variable][response.id] = subject;
          this.item$[variable][response.id] = observable;
          subject.next(optimisticItem);
        } else {
          this.$items[variable].next(Object.values(this.items[variable].filter(i => i.id !== -1)));
          subject.error('CreateFailed: Unable to parse response.');
          subject.complete();
        }
      },
      (error) => {
        this.$items[variable].next(Object.values(this.items[variable].filter(i => i.id !== -1)));
        subject.error(error);
        subject.complete();
    });

    return observable;
  }

  /**
   * @deprecated Marked as deprecated because of lack of updates. Use patch or update this function first.
   */
  public cachedUpdate(item: Item, options: IGraphQLRequestOptions) {

    // TODO: this "put" is hardly used anywhere. might need to look at this one some more if we need it. prefer patch.

    const { graphql: { variable } } = options;
    const id = item.id;

    this.$item[variable][id] = this.$item[variable][id] || new ReplaySubject<Item>(1);
    this.item$[variable][id] = this.item$[variable][id] || this.$item[variable][id].pipe(distinctUntilChanged());

    // optimistically patch item
    const backup = this._unreference(this.items[variable][id]);
    const optimisticItem = Object.assign({}, this.items[variable][id], item);
    if (this.$item[variable][id]) {
      this.$item[variable][id].next(this._unreference(optimisticItem));
    }
    this.items[variable][id] = optimisticItem;
    this.$items[variable].next(Object.values(this.items[variable].filter(x => !!x)));

    super.requestUpdate<Item>(item, { id, ...options })
      .subscribe((data: Item) => {
        if (this._different(this.items[variable][id], data)) {
          if (this.$item[variable][id]) {
            this.$item[variable][id].next(data);
          }
          this.items[variable][id] = data;
          this.$items[variable].next(Object.values(this.items[variable].filter(x => !!x)));
        }
      },
        () => {
          // restore optimistically changed item if operation fails
          if (this.$item[variable][id]) {
            this.$item[variable][id].next(this._unreference(backup));
          }
          this.items[variable][id] = backup;
          this.$items[variable].next(Object.values(this.items[variable]));
        });

    return this.item$[variable][id];
  }

  public cachedPatch(changes: Partial<Item>, options: IIdGraphQLRequestOptions) {

    const { id, graphql: { variable } } = options;

    const result = new Subject<Item>();
    super.requestPatch<Item, ItemResponse>(changes, options)
      .subscribe((response: ItemResponse) => {
        // generate new version for the updated item
        const cachedItem = this._unreference(this.items[variable][id]);
        const item = Object.assign({}, cachedItem, changes, response) as Item;

        // update cache with new item
        if (this._different(this.items[variable][item.id], item)) {
          if (this.$item[variable][item.id]) {
            this.$item[variable][item.id].next(item);
          }
          this.items[variable][item.id] = item;
        }

        // notify listeners
        this.$items[variable].next(Object.values(this.items[variable].filter(x => !!x)));
        result.next(item);
        result.complete();
      },
        // tslint:disable-next-line: no-identical-functions
        (error) => {
          result.error(error);
          result.complete();
        });

    return result.asObservable();
  }

  public cachedPatchMany(changes: Partial<Item>, options: IGraphQLRequestOptions): Observable<Item[]> {

    const { ids, graphql: { variable } } = options;

    if (!ids || !ids.length) {
      return of([]);
    }

    const result = new Subject<Item[]>();
    super.requestPatchMany<Item, ItemResponse>(changes, options)
      .subscribe((responses: ItemResponse[]) => {
        // generate new versions for the updated item
        const items = responses.map(response => {
          const cachedItem = this._unreference(this.items[variable][response.id]);
          return Object.assign({}, cachedItem, changes, response) as Item;
        });

        // update cache with new items
        items.forEach(item => {
          if (this._different(this.items[variable][item.id], item)) {
            if (this.$item[variable][item.id]) {
              this.$item[variable][item.id].next(item);
            }
            this.items[variable][item.id] = item;
          }
        });

        // notify listeners
        this.$items[variable].next(Object.values(this.items[variable].filter(x => !!x)));
        result.next(items);
        result.complete();
      },
        // tslint:disable-next-line: no-identical-functions
        (error) => {
          result.error(error);
          result.complete();
        });

    return result.asObservable();
  }

  public cachedDeleteMany(options: IGraphQLRequestOptions) {
    const { ids, graphql: { variable } } = options;

    const result = new Subject<boolean>();
    super.requestDeleteMany(options)
      .subscribe(() => {
        if (ids) {
          ids.forEach(id => {
            if (this.$item[variable][id]) {
              if (this.$item[variable][id]) {
                this.$item[variable][id].complete();
              }
              delete this.$item[variable][id];
            }
            delete this.items[variable][id];
          });
          this.$items[variable].next(Object.values(this.items[variable].filter(x => !!x)));
        }
        result.next(true);
        result.complete();
      },
        (error) => {
          result.error(error);
          result.complete();
        });
    return result.asObservable();
  }

  public cachedDelete(options: IIdGraphQLRequestOptions) {

    const { id, graphql: { variable } } = options;

    // optimistically delete item
    const backup = this.items[variable][id];
    delete this.items[variable][id];
    this.$items[variable].next(Object.values(this.items[variable].filter(x => !!x)));

    const result = new Subject<boolean>();
    super.requestDelete(options)
      .subscribe(() => {
        if (this.$item[variable][id]) {
          if (this.$item[variable][id]) {
            this.$item[variable][id].complete();
          }
          delete this.$item[variable][id];
        }
        delete this.items[variable][id];
        this.$items[variable].next(Object.values(this.items[variable].filter(x => !!x)));
        result.next(true);
        result.complete();
      },
        (error) => {
          result.error(error);
          result.complete();
          // restore optimistically changed item if operation fails
          this.items[variable][id] = backup;
          this.$items[variable].next(Object.values(this.items[variable].filter(x => !!x)));
        });
    return result.asObservable();
  }

  public cacheIt(node: INode) {
    console.log('Cache node:', node.id);
    this.$item[node.entity] = this.$item[node.entity] || {};
    this.item$[node.entity] = this.item$[node.entity] || {};
    // structure example: this.$item['company'][27] = { name: 'company 27' }
    // this.$items[node.entity][node.id] = this.$items[node.entity][node.id] || new ReplaySubject<Item[]>(1);
    // this.item$[node.entity][node.id] = this.item$[node.entity][node.id] || this.$item[node.entity][node.id].asObservable();
    this.$items[node.entity] = this.$items[node.entity] || new ReplaySubject<Item[]>(1);
    // this.items$[node.entity] = this.items$[node.entity] || this.$items[node.entity].asObservable();

    // optimistically start with the item from the main array
    this.items[node.entity] = this.items[node.entity] || [];
    this.items[node.entity][node.id] = node as unknown as Item;
    //  private items: Record<SpecType, Item[]> = {};
    this.$items[node.entity].next(Object.values(this.items[node.entity].filter(x => !!x)));

    const result = new Subject<boolean>();
    result.next(true);
    result.complete();
    return result.asObservable();
  }

  private _closeUnusued(variable: string) {
    // look at each cached Observer and check if it still exists. complete and remove it otherwise.
    this.$item[variable] = Object.entries(this.$item[variable]).reduce((record: Record<ID, ReplaySubject<Item>>, [key, $data]) => {
      const id = +key; // typing is lost in Object.entries. should be fine to restore it here, since all IDs are numbers
      if (this.items[variable][id]) {
        $data.next(this.items[variable][id]);
        record[id] = $data;
      } else {
        $data.complete();
        delete this.item$[variable][id];
      }
      return record;
    }, {});
  }

  private _unreference(data: Item): Item {
    try {
      return JSON.parse(JSON.stringify(data)) as Item;
    } catch (e) {
      return Object.assign({}, data) as Item;
    }
  }

  private _different(a: Partial<Item>, b: Partial<Item>) {
    return !this._identical(a, b);
  }

  private _identical(a: Partial<Item>, b: Partial<Item>) {
    return JSON.stringify(a) === JSON.stringify(b);
  }

}
