import { Entity } from './entity.interface';
import { ID } from './model.interface';

export interface INode {
  id: ID;
  level: number;
  name: string;
  parents: number[];
  pathParentId: number;
  path?: Partial<INode>[];
  children: INode[];
  type: string;
  entity: string;
}

export class EntityTreeNode implements INode {
  id: ID;
  level: number;
  name: string;
  parents: number[];
  pathParentId: number;
  path?: Partial<INode>[];
  children: INode[];
  type: string;
  entity: string;
}
