import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { FieldSpecification } from 'src/app/core/interfaces/specification.interface';
import { LoadingService } from 'src/app/core/services/loading.service';
import { FormService } from 'src/app/shared/services/form.service';

@Component({
  selector: 'app-grid-creator',
  templateUrl: './grid-creator.component.html',
  styleUrls: ['./grid-creator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridCreatorComponent implements OnInit {

  @Input() public form: FormGroup;
  @Input() public control: AbstractControl;
  @Input() public fieldSpec: FieldSpecification;
  @Input() public breadcrumbs: string[];
  @Input() public key: string;
  @Input() public multiple: boolean;

  constructor(
    private formService: FormService,
    public loadingService: LoadingService,
  ) { }

  ngOnInit() {
  }

  public getControl(key: string) {
    return this.control.get(key);
  }

  public getFieldSpec(key: string) {
    return this.fieldSpec && this.fieldSpec.fields && this.fieldSpec.fields[key];
  }

  public getControls() {
    return this.control && (this.control as FormArray).controls;
  }

  public getFormGroups() {
    return this.control && (this.control as FormArray).controls as FormGroup[];
  }

  // Adds control to an FormArray Object;
  public addControl() {
    const newControl = this.formService.createFormGroup(this.fieldSpec.fields, {}, this.multiple);
    (this.control as FormArray).push(newControl);
    this.control.enable();
    this.control.markAsDirty();
  }

  // Duplicate a control in FormArray Object;
  public duplicateControl(control: AbstractControl, index = -1) {
    const newControl = this.formService.createFormGroup(this.fieldSpec.fields, {}, this.multiple);
    newControl.patchValue(control.value);
    if (index < 0) {
      (this.control as FormArray).push(newControl);
    } else {
      (this.control as FormArray).insert(index + 1, newControl);
    }
    this.control.enable();
    this.control.markAsDirty();
  }

  // Removes control and values to an FormArray Object;
  public removeControl(index: number) {
    (this.control as FormArray).removeAt(index);
    this.control.markAsDirty();
  }

}
