import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  get state$() { return this.$state.asObservable().pipe(distinctUntilChanged()); }
  $state = new ReplaySubject<boolean>(1);
  private _isOpen = false;

  constructor() { }

  toggle(): boolean {
    this._isOpen = !this._isOpen;
    this.$state.next(this._isOpen);
    return this._isOpen;
  }

  close() {
    this._isOpen = false;
    this.$state.next(false);
  }

  open() {
    this._isOpen = true;
    this.$state.next(true);
  }
}
