import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LoadingService } from '../../../../core/services/loading.service';
import { AccessAction } from '../../../interfaces/access.action.class';
import { MetaAction, MetaActions } from '../../../interfaces/access.meta.interface';

@Component({
  selector: 'app-action-list',
  templateUrl: './action-list.component.html',
  styleUrls: ['./action-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ActionListComponent
    }
  ]
})
export class ActionListComponent implements OnInit, ControlValueAccessor {

  @Input() meta: MetaActions;
  actions: AccessAction[] = [];
  newMeta?: MetaAction;

  // form control support
  touched = false;
  disabled = false;
  onChange = () => {};
  onTouched = () => {};

  constructor(
    public loadingService: LoadingService
  ) { }

  ngOnInit() {
  }

  // update
  public update(action: AccessAction, index: number) {
    this.actions = this.actions = [...this.actions.slice(0, index), action, ...this.actions.slice(index + 1)];
    this.onChange();
  }

  // add or remove actions
  public add(meta?: MetaAction) {
    if (!meta) {
      return;
    }
    const action = new AccessAction().setMeta(meta);
    this.actions.push(action);
    this.markAsTouched();
    this.onChange();
  }

  public duplicate(action: AccessAction, index = -1) {
    const newAction = new AccessAction(action.toString()).setMeta(action.meta);
    if (index < 0) {
      this.actions.push(newAction);
    } else {
      this.actions = [...this.actions.slice(0, index), action, ...this.actions.slice(index)];
    }
    this.markAsTouched();
    this.onChange();
  }

  public remove(index: number) {
    this.actions = [...this.actions.slice(0, index), ...this.actions.slice(index + 1)];
    this.markAsTouched();
    this.onChange();
  }

  // form control support
  writeValue(rawInput: string[]): void {
    this.actions = rawInput.map(x => new AccessAction(x).setMeta(this.meta));
    this.markAsTouched();
  }

  registerOnChange(fn: (values: string[]) => void): void {
    this.onChange = () => fn(this.actions.map(x => x.toString()));
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

}
