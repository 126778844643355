export const environment = {
  production: true,
  apiPath: '',
  accessSystemPaths: ['/acaconfig', '/acaapi'],
  nodesPath: '/acaconfig',
  loginPath: '/auth/v2',
  entitiesPath: '/entities/v2',
  application: 'acaconfig',
  title: 'AcaConfig',
  // ignore token management completely (use it only for local development to skip logins)
  skipLoginValidation: false
};
