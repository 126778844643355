import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-toast-dialog',
  templateUrl: './toastdialog.component.html',
})
export class ToastWarningDialogComponent {
  params: {};
  warningText: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public diaRef: MatDialogRef<ToastWarningDialogComponent>,
    private translate: TranslateService
  ) {
    this.warningText = this.data.warningText.split('NEW_LINE');
    console.log('this.warningText:', this.warningText);
  }

  return(data: boolean): void {
    this.diaRef.close(data);
  }
}
