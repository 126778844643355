import { Injectable } from '@angular/core';
import * as jwtDecode from 'jwt-decode';
import { CookieService } from 'ngx-cookie-service';
import { ReplaySubject } from 'rxjs';
import { distinctUntilChanged, shareReplay } from 'rxjs/operators';
import * as storage from '../classes/storage';

@Injectable(
  //   {
  //   providedIn: 'root'
  // }
)
export class UserService {

  private $user = new ReplaySubject<string>(1);
  public user$ = this.$user.pipe(distinctUntilChanged(), shareReplay(1));

  constructor(
    private cookieService: CookieService
  ) {
    this.refresh();
  }

  public refresh() {
    try {
      this.updateFromCookie();
      const storedTokens = storage.sessionGetItem('tokens');
      if (storedTokens) {
        const tokens = JSON.parse(storedTokens);
        const idToken = tokens && tokens.id && jwtDecode(tokens.id) as any;
        const name = idToken ? idToken.name || idToken['custom:externalId'] : '';
        if (name) {
          this.$user.next(name);
        }
      }
    } catch (e) {
      return;
    }
  }

  private updateFromCookie() {
    try {
      if (this.cookieService && this.cookieService.check('id_token')) {
        const cookies: any = this.cookieService.getAll();
        const token: Record<string, string> = {
          access: this.cookieService.get('access_token').replace(/"/g, ''),
          refresh: this.cookieService.get('refresh_token').replace(/"/g, ''),
          id: this.cookieService.get('id_token'),
        };
        storage.sessionSetItem('tokens', JSON.stringify(token));
        this.cookieService.delete('id_token');
        this.cookieService.delete('refresh_token');
        this.cookieService.delete('access_token');
        console.log('UserService: Moved token cookies to session storage.');
      }
    } catch (error) {
      console.error('TokenInterceptor: Error occurred while digesting cookies', error);
      return;
    }
  }

  /**
   * Invalidate current user session and redirects to login page.
   * Invalidate current user session and logout.
   */
  public logout(): void {
    console.log('UserService:logout()');
    storage.sessionRemoveItem('tokens');
    window.location.href = 'https://nologout.academedia.se/';
  }
}
