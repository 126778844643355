const listeners = [];

export const sessionSetItem = (key: string, value: any): void => {
  window.sessionStorage.setItem(key, value);
};

export const sessionRemoveItem = (... keys: string[]): void => {
  keys.forEach(key => window.sessionStorage.removeItem(key));
};

export const sessionGetItem = (key: string): string | undefined => {
  return window.sessionStorage.getItem(key) || undefined;
};

export const sessionHasItem = (key: string): boolean => {
  return !!window.sessionStorage.getItem(key);
};

export const localSetItem = (key: string, value: any): void => {
  window.localStorage.setItem(key, value);
};

export const localRemoveItem = (... keys: string[]): void => {
  keys.forEach(key => window.localStorage.removeItem(key));
};

export const localGetItem = (key: string): string | undefined => {
  return window.localStorage.getItem(key) || undefined;
};

export const localHasItem = (key: string): boolean => {
  return !!window.localStorage.getItem(key);
};
