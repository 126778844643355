import { AfterContentChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, QueryList, TemplateRef, ViewChildren } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { equality } from 'src/app/core/classes/utilities';
import { Entity } from 'src/app/core/interfaces/entity.interface';
import { FieldSpecification } from 'src/app/core/interfaces/specification.interface';
import { EntitiesService } from 'src/app/core/services/entities.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { TypeTemplateDirective } from 'src/app/shared/directives/type.directive';
import { FormService } from 'src/app/shared/services/form.service';

@Component({
  selector: 'app-grid-cell',
  templateUrl: './grid-cell.component.html',
  styleUrls: ['./grid-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridCellComponent implements OnInit, AfterContentChecked, OnDestroy {

  private subscriptions = new Map<string, Subscription>();

  // form
  private originalValue: any;
  @Input() public form: FormGroup;
  @Input() public control: AbstractControl;
  @Input() public fieldSpec: FieldSpecification;
  @Input() public breadcrumbs: string[];
  @Input() public key: string;
  @Input() public multiple: boolean;

  public header: string;

  // helpers
  public objectKeys = Object.keys;

  // for referenced entities
  public entities$: Observable<Entity[]>;
  public entitiesGrouped$: Observable<Record<string, Entity[]>>;

  // templating stuff
  public active = new EventEmitter<boolean>(true);
  public references: { [key: string]: TemplateRef<any> } = {};
  @ViewChildren(TypeTemplateDirective) set _refs(refs: QueryList<TypeTemplateDirective>) {
    this.references = refs.reduce((obj: any, { appTemplateType: type, template }) => (obj[type] = template) && obj, {});
    this.changeDetector.detectChanges(); // To avoid ExpressionChangedAfterItHasBeenCheckedError
    this.active.emit(true);
  }

  constructor(
    public element: ElementRef,
    private entitiesService: EntitiesService,
    private formService: FormService,
    private changeDetector: ChangeDetectorRef,
    public loadingService: LoadingService,
  ) { }

  ngOnInit() {
    this.header = (this.breadcrumbs && this.breadcrumbs.length > 2) ? this.breadcrumbs.slice(-2, -1).join() : '';
    this.originalValue = this.control && this.control.value;
    if (this.fieldSpec && this.fieldSpec.ref) {
      if (this.control) {
        this.control.disable();
      } else {
        console.warn('FormCreator: Missing control for %s', this.key);
      }
      if (this.fieldSpec.ref.groupBy) {
        this.entitiesGrouped$ = this.getEntitiesGrouped(this.fieldSpec);
      }
      this.entities$ = this.getEntities(this.fieldSpec);
    }

    this.subscriptions.set('ControlLoadingStatus', this.loadingService.loading$
      .subscribe(loading => this.control && (loading ? this.control.disable() : !(this.fieldSpec.system || this.fieldSpec.readonly) && this.control.enable()))
    );
  }

  ngAfterContentChecked() {
    const el = this.element.nativeElement as HTMLElement;
    const collection = el.getElementsByClassName('mat-form-field');
    if (collection.length > 0) {
      const target = collection.item(0) as HTMLInputElement;
      if (this.control && this.control.invalid) {
        target.classList.add('mat-form-field-invalid');
      } else {
        target.classList.remove('mat-form-field-invalid');
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public reset(el: HTMLElement) {
    setTimeout(() => {
      if (el.blur) {
        el.blur();
      }
      this.control.setValue(this.originalValue);
      this.control.markAsPristine();
    }, 50);
  }

  getEntities({ ref: { to, compare, compareTo }, sortBy = 'name' }: FieldSpecification) {
    return this.entitiesService.getAllByType(to)
      .pipe(
        map(entities => entities
          .filter(entity => entity[compare] === compareTo)
          .sort((a, b) => {
            // TODO: extract to some kind of EntitySorter shared function
            if (sortBy) {
              const sortValueA = sortBy in a && a[sortBy].toLowerCase();
              const sortValueB = sortBy in b && b[sortBy].toLowerCase();
              return sortValueA < sortValueB ? -1 : 1;
            }
            return a.id < b.id ? -1 : 1;
          })
        ),
        tap(() => {
          if (this.control && !this.fieldSpec.readonly) {
            this.control.enable();
          } else {
            console.warn('FormCreator:getEntities:tap(): Missing control for reference to %s', to);
          }
        })
      );
  }

  getEntitiesGrouped(fieldSpec: FieldSpecification) {

    const { ref: { groupBy } } = fieldSpec;
    return this.getEntities(fieldSpec)
      .pipe(
        map(
          entities => entities.reduce((list: Record<string, Entity[]>, entity: Entity) => {
            const header = entity[groupBy];
            list[header] = list[header] || [];
            list[header].push(entity);
            return list;
          }, {})
        )
      );
  }

  compareFn(c1: Entity, c2: Entity): boolean {
    return c1 && c1.id && c2 && c2.id ? c1.id === c2.id : c1 === c2;
  }

  equalityFn(a: any, b: any): boolean {
    return equality(a, b);
  }

  getAsDateObject(date: string): Date {
    return new Date(date);
  }

  // grab a nested form control for object types
  protected getControl(key: string) {
    return this.control.get(key);
  }

  protected getFieldSpec(key: string) {
    return this.fieldSpec && this.fieldSpec.fields && this.fieldSpec.fields[key];
  }

  protected getControls() {
    // TODO: as FormArray eller FormGroup
    return this.control && (this.control as FormArray).controls;
  }

  // Adds control to an FormArray Object;
  public addControl() {
    console.log('this.fieldSpec.fields', this.fieldSpec.fields);
    const newFormControl = this.fieldSpec.fields
      ? this.formService.createFormGroup(this.fieldSpec.fields, {}, this.multiple)
      : this.formService.createFormControl();
    this.control.enable();
    console.log('this.control', this.control, newFormControl);
    (this.control as FormArray).push(newFormControl);
    this.control.markAsDirty();
  }

  // Removes control and values to an FormArray Object;
  public removeControl(index: number) {
    (this.control as FormArray).removeAt(index);
    this.control.markAsDirty();
  }
}
