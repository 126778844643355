import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { Subscription } from 'rxjs';
import { delay, tap } from 'rxjs/operators';
import { ID } from '../../../../core/interfaces/model.interface';
import { EntityTreeNode } from '../../../../core/interfaces/node.interface';
import { ExternalSystemsService } from '../../../../core/services/externalSystems.service';

export interface NodeSelectorComponentResponse {
  selection: number[];
  ok: boolean;
}

/** Flat node with expandable and level information */
export class EntityTreeFlatNode extends EntityTreeNode {
  id: ID;
  name: string;
  level: number;
  expandable: boolean;
  constructor(node: EntityTreeNode, level = 0) {
    super();
    this.id = node.id;
    this.name = node.name;
    this.level = level;
    this.expandable = !!node.children && node.children.length > 0;
  }
}

@Component({
  selector: 'app-node-selector',
  templateUrl: './node-selector.component.html',
  styleUrls: ['./node-selector.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class NodeSelectorComponent implements OnInit, OnDestroy, AfterViewInit {

  private subscriptions = new Map<string, Subscription>();

  // tree nodes
  flatNodeMap = new Map<EntityTreeFlatNode, EntityTreeNode>();
  nestedNodeMap = new Map<EntityTreeNode, EntityTreeFlatNode>();

  // tree components
  treeControl: FlatTreeControl<EntityTreeFlatNode>;
  treeFlattener: MatTreeFlattener<EntityTreeNode, EntityTreeFlatNode>;
  dataSource: MatTreeFlatDataSource<EntityTreeNode, EntityTreeFlatNode>;
  nodeSelection = new SelectionModel<EntityTreeFlatNode>(true /* multiple */);

  constructor(
    private externalSystemsService: ExternalSystemsService,
    private dialogRef: MatDialogRef<NodeSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { nodes: number[] },
  ) {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<EntityTreeFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    this.dataSource.data = []; // need to init this here so TreeController doesn't throw errors
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngAfterViewInit() {
    this.subscriptions.set('organizationDataChanged', this.externalSystemsService.organization$
      .pipe(
        tap((data: EntityTreeNode[]) => {
          this.dataSource.data = data;
        }),
        delay(100),
      )
      .subscribe((data: EntityTreeNode[]) => {
        const flatNodes = Array.from(this.flatNodeMap.keys()).filter(node => this.data.nodes.includes(node.id));
        flatNodes.forEach((flatNode) => {
          this.expandAllParents(flatNode);
          this.treeControl.expand(flatNode);
          this.nodeSelectionToggle(flatNode, true);
        });
        this.dataSource.data = data;
      }));
  }

  getLevel = (node: EntityTreeFlatNode) => node.level;

  isExpandable = (node: EntityTreeFlatNode) => node.expandable;

  getChildren = (node: EntityTreeNode): EntityTreeNode[] => node.children;

  hasChild = (_: number, node: EntityTreeFlatNode) => node.expandable;

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: EntityTreeNode, level: number) => {
    const flatNode = this.nestedNodeMap.get(node) || new EntityTreeFlatNode(node, level);
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: EntityTreeFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    return descendants.length > 0 && descendants.every(child => this.nodeSelection.isSelected(child));
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: EntityTreeFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.nodeSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  nodeSelectionToggle(node: EntityTreeFlatNode, forceSelect = false): void {
    // forceSelect ? this.nodeSelection.select(node) : this.nodeSelection.toggle(node);
    this.nodeSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.nodeSelection.isSelected(node)
      ? this.nodeSelection.select(...descendants)
      : this.nodeSelection.deselect(...descendants);

    // Force update for the parent
    descendants.forEach(child => this.nodeSelection.isSelected(child));
    this.checkAllParentsSelection(node);
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  nodeLeafSelectionToggle(node: EntityTreeFlatNode): void {
    this.nodeSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  expandAllParents(node: EntityTreeFlatNode): void {
    let parent: EntityTreeFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.treeControl.expand(parent);
      parent = this.getParentNode(parent);
    }
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: EntityTreeFlatNode): void {
    let parent: EntityTreeFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: EntityTreeFlatNode): void {
    const nodeSelected = this.nodeSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.length > 0 && descendants.every(child => {
      return this.nodeSelection.isSelected(child);
    });
    if (nodeSelected && !descAllSelected) {
      this.nodeSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.nodeSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: EntityTreeFlatNode): EntityTreeFlatNode | null {
    const currentLevel = this.getLevel(node);
    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;
    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];
      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }

    return null;
  }

  closeDialog() {
    this.flatNodeMap.forEach(flatNode => {
      // we only care about the top level selected node, since access is inherited
      const node = this.nestedNodeMap.get(flatNode);
      if (node && this.descendantsAllSelected(node)) {
        const descendants = this.treeControl.getDescendants(node);
        descendants.forEach(descendant =>
          // each node might appear in different places in the tree
          this.nodeSelection.selected.forEach(selected =>
            selected.id === descendant.id && this.nodeSelection.deselect(selected))
        );
      }
    });
    const selection = Array.from(new Set(this.nodeSelection.selected.map(node => node.id)));
    this.dialogRef.close({ selection, ok: true } as NodeSelectorComponentResponse);
  }

}
