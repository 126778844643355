import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import {
  IIdRequestOptions,
  IRequestOptions,
} from "../interfaces/data.service.interface";
import { Observable, ReplaySubject, of, throwError } from "rxjs";
import { catchError, mapTo, tap } from "rxjs/operators";

import { AbstractDataService } from "./abstract.data.service";
import { NotificationService } from "../services/notification.service";
import { environment } from "src/environments/environment";

export abstract class RestService extends AbstractDataService {
  protected readonly $errors = new ReplaySubject<string>(1);
  public readonly errors$ = this.$errors.asObservable();

  constructor(
    protected http: HttpClient,
    protected notificationService: NotificationService,
    protected resource = "/"
  ) {
    super();
  }

  protected errorHandler(error: HttpErrorResponse | Error) {
    console.log("rest.service:error", error);
    if (error instanceof Error) {
      // these errors are generated in RestService
      this.$errors.next(`${error.name}: ${error.message}`);
      this.notificationService.error(`${error.name}: ${error.message}`);
      return throwError(`RestService: Error occured: ${error.message}`);
    } else if (
      error instanceof HttpErrorResponse &&
      error.error instanceof ErrorEvent
    ) {
      // ErrorEvents are generated by the browser (network errors, etc)
      this.$errors.next(`${error.error.type}: ${error.error.message}`);
      this.notificationService.error(
        `${error.error.type}: ${error.error.message}`
      );
    } else {
      // HttpErrorResponse are from the server (500 internal server error, 404 not found, etc)
      this.$errors.next(`${error.status} ${error.statusText}: ${error.url}`);
      this.notificationService.http(this.resource, error);
    }
    console.error(
      `RestService: Error on ${this.resource}: ${error.status} ${error.statusText} (${error.url})`
    );
    return of([]);
    // return throwError(`CachedRestService: Error on ${this.resource}: ${error.status} ${error.statusText} (${error.url})`);
  }

  public requestGetAll<S>(options: IRequestOptions): Observable<S[]> {
    return this.http
      .get<S[]>(this.generateUrl(options), { params: options.params })
      .pipe(
        catchError(this.errorHandler.bind(this)),
        tap(() => this.$errors.next(""))
      );
  }

  public requestGet<T>(options: IIdRequestOptions): Observable<T> {
    return this.http
      .get<T>(this.generateUrl(options), { params: options.params })
      .pipe(
        catchError(this.errorHandler.bind(this)),
        tap(() => this.$errors.next(""))
      );
  }

  public requestCreate<T>(entity: T, options: IRequestOptions): Observable<T> {
    return this.http
      .post<T>(this.generateUrl(options), entity, { params: options.params })
      .pipe(
        catchError(this.errorHandler.bind(this)),
        tap(() => this.$errors.next(""))
      );
  }

  public requestUpdate<T>(
    entity: T,
    options: IIdRequestOptions
  ): Observable<T> {
    console.assert(!!options.id, "RestService.requestUpdate(): ID required.");
    return this.http
      .put<T>(this.generateUrl(options), entity, { params: options.params })
      .pipe(
        catchError(this.errorHandler.bind(this)),
        tap(() => this.$errors.next(""))
      );
  }

  public requestPatch<T>(
    partial: Partial<T>,
    options: IIdRequestOptions
  ): Observable<T> {
    console.assert(!!options.id, "RestService.requestPatch(): ID required.");
    return this.http
      .patch<T>(this.generateUrl(options), partial, { params: options.params })
      .pipe(
        catchError(this.errorHandler.bind(this)),
        tap(() => this.$errors.next(""))
      );
  }

  public requestDelete(options: IIdRequestOptions) {
    console.assert(!!options.id, "RestService.requestDelete(): ID required.");
    return this.http.delete(this.generateUrl(options)).pipe(
      catchError(this.errorHandler.bind(this)),
      tap(() => this.$errors.next("")),
      mapTo(true)
    );
  }

  private generateUrl({
    resource = this.resource,
  }: IRequestOptions | IIdRequestOptions) {
    return [environment.apiPath, resource]
      .filter((x) => !!x)
      .join("/")
      .split("://")
      .map((p) => p.replace(/\/\//, "/"))
      .join("://");
  }
}
