import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { EntityEditorDialogComponent } from './components/entity-editor-dialog/entity-editor-dialog.component';
import { EntityEditorComponent } from './components/entity-editor/entity-editor.component';
import { FormCreatorComponent } from './components/form-creator/form-creator.component';
import { GridCellComponent } from './components/grid-creator/grid-cell/grid-cell.component';
import { GridCreatorComponent } from './components/grid-creator/grid-creator.component';
import { LoadingBarComponent } from './components/loading-bar/loading-bar.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { ActionListComponent } from './components/statement-editor/action-list/action-list.component';
import { ActionComponent } from './components/statement-editor/action-list/action/action.component';
import { NodeSelectorComponent } from './components/statement-editor/node-selector/node-selector.component';
import { ResourceListComponent } from './components/statement-editor/resource-list/resource-list.component';
import { ResourceComponent } from './components/statement-editor/resource-list/resource/resource.component';
import { StatementEditorComponent } from './components/statement-editor/statement-editor.component';
import { ToastWarningDialogComponent } from './components/toast-dialog/toastdialog.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { WarningDialogComponent } from './components/warning-dialog/warningdialog.component';
import { TypeTemplateDirective } from './directives/type.directive';
import { MaterialModule } from './material.module';
import { PluckPipe } from './pipes/pluck.pipe';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
  ],
  exports: [
    EntityEditorComponent,
    FormCreatorComponent,
    LoadingBarComponent,
    LoadingSpinnerComponent,
    SidenavComponent,
    ToastWarningDialogComponent,
    ToolbarComponent,
    WarningDialogComponent,
    GridCreatorComponent,
    GridCellComponent,
    FormsModule,
    ReactiveFormsModule,
    StatementEditorComponent,
    ActionListComponent,
    ResourceListComponent,
    ActionComponent,
    ResourceComponent,
    NodeSelectorComponent,
    PluckPipe
  ],
  declarations: [
    EntityEditorComponent,
    FormCreatorComponent,
    LoadingBarComponent,
    LoadingSpinnerComponent,
    SidenavComponent,
    ToolbarComponent,
    WarningDialogComponent,
    ToastWarningDialogComponent,
    TypeTemplateDirective,
    EntityEditorDialogComponent,
    GridCreatorComponent,
    GridCellComponent,
    StatementEditorComponent,
    ActionListComponent,
    ResourceListComponent,
    ActionComponent,
    ResourceComponent,
    NodeSelectorComponent,
    PluckPipe
  ],
})

export class SharedModule { }
