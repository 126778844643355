import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoadingService } from '../../../../../core/services/loading.service';
import { AccessAction } from '../../../../interfaces/access.action.class';
import { MetaActions } from '../../../../interfaces/access.meta.interface';

@Component({
  selector: 'app-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.scss']
})
export class ActionComponent implements OnInit {

  @Input() action: AccessAction;
  @Input() meta: MetaActions;

  @Output() changes = new EventEmitter();
  editing = false;
  _subject: string;
  preview: string;

  constructor(
    public loadingService: LoadingService
  ) { }

  ngOnInit() {
    this.updatePreview();
    this._subject = this.action.subject;
  }

  toggleEdit() {
    this.editing = !this.editing;
    this.updatePreview();
  }

  setSubject(subject: string) {
    console.log('subject', subject);
    this.action.subject = subject;
    // this._subject = subject;
    this.updatePreview();
    this.changes.emit(this.action);
  }

  updatePreview() {
    this.preview = this.action.toString();
  }

}
