import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pluck'
})
export class PluckPipe implements PipeTransform {

  /**
   * Pipe to return a value at path in an object. Example: plucking `"a.b"` from `{ a: { b: 1 } }` returns `1`.
   * @param obj the object to pluck from
   * @param path the path to the value
   * @param filler value to return when plucked value isn't found
   * @param args additional args (not used)
   * @returns the value at path in obj
   */
  transform(obj: Record<string, any>, path: string, filler = '-', ...args: unknown[]): unknown {
    if (!obj) {
      return filler;
    }

    const _path = path.split('.');
    let val = obj;
    let i = 0;

    for (let p of _path) {
      if (val && val[p]) {
        val = val[p];
        i++;
      } else {
        break;
      }
    }

    return (i === _path.length) ? val : filler;
  }

}
