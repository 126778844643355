import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[appTemplateType]'
})

export class TypeTemplateDirective {
  @Input() appTemplateType: string;
  constructor(public template: TemplateRef<any>) {}
}
