import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as jwtDecode from 'jwt-decode';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, shareReplay, switchMap, take } from 'rxjs/operators';
import { environment as env } from 'src/environments/environment';
import * as storage from '../classes/storage';
import { LoadingService } from '../services/loading.service';
import { UserService } from '../services/user.service';

interface IToken { id: string; access: string; refresh: string; }

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private $refreshedToken: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  private readonly refreshedToken$: Observable<string> = this.$refreshedToken.pipe(filter(x => !!x), shareReplay(1)) as Observable<string>;

  constructor(
    private loadingService: LoadingService,
    private userService: UserService,
    private http: HttpClient,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // keep track of the number of pending requests
    this.loadingService.add();

    // keep track of status and elapsed time
    // const sent = Date.now();

    const ignoreList = ['auth', 'login', 'assets'];
    const standardRequest = !ignoreList.some(url => request.url.indexOf(url) > -1);
    if (standardRequest) {
      // check if there's a token
      let token = this.getIdToken();
      if (!token && !window.location.href.endsWith('/login')) {
        // try to parse it from cookies. this happens just after login.
        this.userService.refresh();
        token = this.getIdToken();
        // stop trying if there's no token there either.
        if (!token) {
          console.log('TokenInterceptor: Missing tokens, redirecting to login');
          window.location.href = '/login';
        }
      }
      request = token ? this.addToken(request, token) : request;
    }

    return standardRequest && this.tokenExpired()
      ? this.queueAndRefresh(request, next)
      : next
        .handle(request)
        .pipe(
          catchError(error => {
            console.error('TokenInterceptor: Error', error, 'Request', request);
            if (error instanceof HttpErrorResponse && [401].includes(error.status)) {
              if (this.tokenExpired()) {
                return this.queueAndRefresh(request, next);
              }
              return throwError('Token missing');
            } else {
              return throwError(error);
            }
          }),
          finalize(() => {
            this.loadingService.done();
            // const elapsed = Date.now() - sent;
            // console.log(`${request.method} ${request.urlWithParams} (${elapsed} ms).`);
          })
        );
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return token ? request.clone({ headers: new HttpHeaders({ Authorization: `Bearer ${token}` }) }) : request;
  }

  private queueAndRefresh(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      console.log('TokenInterceptor: Token expired: refresh required. Queueing request:', request.url);
      this.isRefreshing = true;
      this.$refreshedToken.next(null);
      this.refresh();
    } else {
      console.log('TokenInterceptor: Token refresh in progress, queueing:', request.url);
    }

    return this.refreshedToken$.pipe(
      filter(token => !!token),
      take(1),
      switchMap(token => {
        console.log('TokenInterceptor: Resending queued request:', request.url);
        return next.handle(this.addToken(request, token));
      }),
      finalize(() => this.loadingService.done())
    );
  }

  /**
   * Refreshes current identity token using an associated refresh token.
   * @returns a new token or undefined if the refresh token is expired
   */
  private refresh() {
    const headers = { headers: new HttpHeaders({ Authorization: this.getIdToken() as string }) };
    const body = { token: this.getRefreshToken() };
    console.log('TokenInterceptor: Refreshing access token...');

    return this.http.post(`${env.loginPath}/refresh/`, body, headers)
      .pipe(
        catchError(error => {
          console.log('TokenInterceptor: Error during /refresh:', error);
          return throwError(error);
        }),
      ).subscribe(
        (data: any) => {
          console.log('TokenInterceptor: Refresh successful.');
          const token = this.getToken();
          try {
            const newToken = JSON.parse(data.body);
            // the refresh call doesn't return a new refresh token, just update the other two
            token.id = newToken.id_token;
            token.access = newToken.access_token;
            storage.sessionSetItem('tokens', JSON.stringify(token));
            this.$refreshedToken.next(token.id);
            this.isRefreshing = false;
          } catch (e) {
            console.warn('TokenInterceptor: Unable to parse refresh token response.');
          }
          return token;
        },
        (err: any) => console.error('TokenInterceptor: Error refreshing access token:', err)
      );
  }

  /**
   * Checks expiration on the user's token.
   * @returns true if the user has an expired token, false if the token is not expired, or undefined if token is missing
   */
  public tokenExpired() {
    const token = this.getToken();
    if (!token || !token.id) {
      return undefined;
    }
    try {
      const data = jwtDecode(token.id) as any;
      const now = (Date.now() / 1000) + 60; // check a minute ahead
      return data && data.exp < now;
    } catch {
      return undefined;
    }
  }

  /**
   * Get token object which contains an identity and refresh token.
   * @returns a token object or empty object if none exists
   */
  public getToken(): IToken {
    try {
      const storedToken = storage.sessionGetItem('tokens');
      const token = JSON.parse(storedToken || '');
      return token;
    } catch (error) {
      // console.log('Error, can´t find token:', error);
      return {} as IToken;
    }
  }

  /**
   * Get the identity token contained in the stored token object.
   * @returns identity token or undefined if none exists
   */
  public getIdToken(): string | undefined {
    const token = this.getToken();
    return token && token.id;
  }

  /**
   * Get the refresh token contained in the stored token object.
   * @returns refresh token or undefined if none exists
   */
  public getRefreshToken(): string | undefined {
    const token = this.getToken();
    return token && token.refresh;
  }

}
