import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ICacheLayer } from '../interfaces/cache.layer.interface';
import { IIdRequestOptions, IRequestOptions } from '../interfaces/data.service.interface';
import { ID, Model } from '../interfaces/model.interface';
import { RestService } from './rest.service';

export abstract class CachedRestService<Item extends Model> extends RestService implements ICacheLayer<Item> {

  // record of all items from GET /resource
  private items: Record<ID, Item> = {}; // TODO: use memcache, indexed db, local storage or something
  protected readonly $items = new ReplaySubject<Item[]>(1);
  public readonly items$ = this.$items.asObservable();

  // individual items from GET /resource/:id
  protected $item: Record<ID, ReplaySubject<Item>> = {};
  protected readonly item$: Record<ID, Observable<Item>> = {};

  constructor(
    protected http: HttpClient,
    protected notificationService: NotificationService,
    protected resource: string,
    protected deserialize: (data: Item, index: number) => Item
  ) {
    super(http, notificationService, resource);
  }

  // helpers
  public cachedPeek(options: IIdRequestOptions) {
    const { id } = options;
    return this.item$[id] || this.cachedGet(options);
  }

  // CRUD
  public cachedGetAll(options: IRequestOptions = {}) {

    super.requestGetAll<Item>(options)
      .pipe(map(items => items ? items.map(this.deserialize) : []))
      .subscribe(
        (data: Item[]) => {
          this.items = data.reduce((record: Record<ID, Item>, item: Item) => {
            record[item.id] = item;
            return record;
          }, {});
          this._closeUnusued();
          this.$items.next(data);
        }
      );

    return this.items$;
  }

  public cachedGet(options: IIdRequestOptions) {

    const { id } = options;

    this.$item[id] = this.$item[id] || new ReplaySubject<Item>(1);
    this.item$[id] = this.item$[id] || this.$item[id].asObservable();

    // optimistically start with the item from the main array
    const item = this.items[id];
    if (item) {
      this.$item[id].next(this._unreference(item));
    }

    super.requestGet<Item>(options)
      .pipe(map(this.deserialize))
      .subscribe((data: Item) => {
        // update if item has been changed since we first retrieved it
        if (this._different(item, data)) {
          if (this.$item[id]) {
            this.$item[id].next(this._unreference(data));
          }
          this.items[id] = this._unreference(data);
          this.$items.next(Object.values(this.items));
        }
      });

    return this.item$[id];
  }

  public cachedCreate(item: Item, options: IRequestOptions = {}) {

    const subject = new ReplaySubject<Item>(1);
    const observable = subject.pipe(distinctUntilChanged());

    // optimistically add it
    const optimisticItem = this.deserialize(item, Date.now());
    this.$items.next([optimisticItem, ...Object.values(this.items)]);
    subject.next(optimisticItem);

    super.requestCreate<Item>(item, options)
      .pipe(map(this.deserialize))
      .subscribe((data: Item) => {
        // This section requiers modification if the backend only returns an ID instead of a complete object.
        // See comments and examples in cached.sparse.data.service:cachedCreate
        const id = data.id;
        this.items[id] = data;

        this.$item[id] = subject;
        this.item$[id] = observable;

        subject.next(data);
        // update the optimistically added item in the main list
        Object.assign(optimisticItem, data);

        // TODO: remove optimistic item on failures
      });

    return observable;
  }

  public cachedUpdate(item: Item, options: IRequestOptions = {}) {

    const id = item.id;

    this.$item[id] = this.$item[id] || new ReplaySubject<Item>(1);
    this.item$[id] = this.item$[id] || this.$item[id].pipe(distinctUntilChanged());

    // optimistically patch item
    const backup = this._unreference(this.items[id]);
    const optimisticItem = Object.assign({}, this.items[id], item);
    if (this.$item[id]) {
      this.$item[id].next(this._unreference(optimisticItem));
    }
    this.items[id] = optimisticItem;
    this.$items.next(Object.values(this.items));

    super.requestUpdate<Item>(item, { id, ...options })
      .subscribe((data: Item) => {
        if (this._different(this.items[id], data)) {
          if (this.$item[id]) {
            this.$item[id].next(data);
          }
          this.items[id] = data;
          this.$items.next(Object.values(this.items));
        }
      },
        // tslint:disable-next-line: no-identical-functions
        () => {
          // restore optimistically changed item if operation fails
          if (this.$item[id]) {
            this.$item[id].next(this._unreference(backup));
          }
          this.items[id] = backup;
          this.$items.next(Object.values(this.items));
        });

    return this.item$[id];
  }

  public cachedPatch(changes: Partial<Item>, options: IIdRequestOptions) {

    const { id } = options;

    this.$item[id] = this.$item[id] || new ReplaySubject<Item>(1);
    this.item$[id] = this.item$[id] || this.$item[id].pipe(distinctUntilChanged());

    // optimistically patch item
    const backup = this._unreference(this.items[id]);
    const optimisticItem = Object.assign({}, this.items[id], changes);
    if (this.$item[id]) {
      this.$item[id].next(this._unreference(optimisticItem));
    }
    this.items[id] = optimisticItem;
    this.$items.next(Object.values(this.items));

    super.requestPatch<Item>(changes, options)
      .pipe(map(this.deserialize))
      .subscribe((data: Item) => {
        if (this._different(this.items[id], data)) {
          if (this.$item[id]) {
            this.$item[id].next(this._unreference(data));
          }
          this.items[id] = this._unreference(data);
          this.$items.next(Object.values(this.items));
        }
      },
        // tslint:disable-next-line: no-identical-functions
        () => {
          // restore optimistically changed item if operation fails
          if (this.$item[id]) {
            this.$item[id].next(this._unreference(backup));
          }
          this.items[id] = backup;
          this.$items.next(Object.values(this.items));
        });

    return this.item$[id];
  }

  public cachedDelete(options: IIdRequestOptions) {

    const { id } = options;

    // optimistically delete item
    const backup = this.items[id];
    delete this.items[id];
    this.$items.next(Object.values(this.items));

    const result = new Subject<boolean>();
    super.requestDelete(options)
      .subscribe(() => {
        if (this.$item[id]) {
          if (this.$item[id]) {
            this.$item[id].complete();
          }
          delete this.$item[id];
        }
        delete this.items[id];
        this.$items.next(Object.values(this.items));
        result.next(true);
      },
        (error) => {
          result.error(error);
          result.complete();
          // restore optimistically changed item if operation fails
          this.items[id] = backup;
          this.$items.next(Object.values(this.items));
        });
    return result.asObservable();
  }

  protected _close(id: ID) {
    if (this.$item[id]) {
      this.$item[id].complete();
      delete this.$item[id];
    }
  }

  private _closeUnusued() {
    // look at each cached Observer and check if it still exists. complete and remove it otherwise.
    this.$item = Object.entries(this.$item).reduce((record: Record<ID, ReplaySubject<Item>>, [key, $data]) => {
      const id = +key; // typing is lost in Object.entries. should be fine to restore it here, since all IDs are numbers
      if (this.items[id]) {
        $data.next(this.items[id]);
        record[id] = $data;
      } else {
        $data.complete();
        delete this.item$[id];
      }
      return record;
    }, {});
  }

  private _unreference(data: Item): Item {
    try {
      return JSON.parse(JSON.stringify(data));
    } catch (e) {
      return Object.assign({}, data);
    }
  }

  private _different(a: Partial<Item>, b: Partial<Item>) {
    return !this._identical(a, b);
  }

  private _identical(a: Partial<Item>, b: Partial<Item>) {
    return JSON.stringify(a) === JSON.stringify(b);
  }

}
