import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Entity, EntityEditorDialogData } from 'src/app/core/interfaces/entity.interface';
import { Fields, FieldSpecification } from 'src/app/core/interfaces/specification.interface';
import { validator } from 'src/app/shared/validators/type.validator';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  formBuilder = new FormBuilder();

  constructor() { }

  public createFormControl() {
    return this.formBuilder.control(null);
  }

  public createFormGroup(fields: Fields, entity: Partial<Entity>, multiple = false) {
    // Loop through each field instance and set value from data if available.
    const formGroupConfig = Object.entries(fields)
      .reduce((groups: any, [key, field]) => {
        const { type, system, readonly, required, fields: childFields } = field;
        const array = field.array && field?.meta?.useFormArray !== false || false; // used by statement-editor
        const value = this.getValueOrDefault(entity, key, field);
        if (array) {
          if (type === 'object') {
            groups[key] = new FormArray(value && value.map((el: any) => this.createFormGroup(childFields, el, multiple)) || []);
          } else if (type === 'number' || type === 'string' || type === 'boolean') {
            groups[key] = this.formBuilder.array(value);
          } else {
            // select/reference etc..
            groups[key] = new FormControl({
              value,
              disabled: Boolean(system) || Boolean(readonly)
            },
              [validator({ required, disabled: system || readonly, type, label: key, array, multiple })]);
          }
        } else {
          // none array
          if (type === 'object') {
            groups[key] = this.createFormGroup(childFields, value, multiple);
          } else {
            groups[key] = new FormControl({
              value,
              disabled: Boolean(system) || Boolean(readonly)
            },
              [validator({ required, disabled: system || readonly, type, label: key })]);
          }
        }
        if (system || readonly) {
          groups[key].disable();
        }
        return groups;
      }, {});
    // console.log('formGroupConfig', formGroupConfig);
    return this.formBuilder.group(formGroupConfig); // Create FormGroup
  }

  private getValueOrDefault(entity: Partial<Entity>, key: string, field: FieldSpecification) {
    const getDefaultValue = () => 'default' in field && ![null, undefined].includes(field.default) ? field.default : field.array ? [] : undefined;
    return [null, undefined].includes(entity && entity[key]) ? getDefaultValue() : entity[key];
  }

  public createForm({ specification, multiple }: EntityEditorDialogData): FormGroup {
    return this.createFormGroup(specification.fields, {} as Entity, !!multiple);
  }

}
