import {
  AfterContentChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef,
  EventEmitter, Input, OnDestroy, OnInit, QueryList, TemplateRef, ViewChildren
} from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { equality } from 'src/app/core/classes/utilities';
import { Entity } from 'src/app/core/interfaces/entity.interface';
import { FieldSpecification } from 'src/app/core/interfaces/specification.interface';
import { EntitiesService } from 'src/app/core/services/entities.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { TypeTemplateDirective } from 'src/app/shared/directives/type.directive';
import { FormService } from 'src/app/shared/services/form.service';

@Component({
  selector: 'app-form-creator',
  templateUrl: './form-creator.component.html',
  styleUrls: ['./form-creator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormCreatorComponent implements OnInit, OnDestroy, AfterContentChecked {

  private subscriptions = new Map<string, Subscription>();

  // form
  private originalValue: any;
  @Input() public form: FormGroup;
  @Input() public control: AbstractControl;
  @Input() public fieldSpec: FieldSpecification;
  @Input() public breadcrumbs: string[];
  @Input() public key: string;
  @Input() public label: string;
  @Input() public multiple: boolean;
  @Input() public refValues$: Observable<Entity[] | undefined> = of(undefined); // use this list to constrain values when validation.in.ref is in use.

  public refValues: Entity[];

  public header: string;

  // helpers
  public objectKeys = Object.keys;

  // for referenced entities
  public entities$: Observable<Entity[]>;
  public entitiesGrouped$: Observable<Record<string, Entity[]>>;

  // templating stuff
  public active = new EventEmitter<boolean>(true);
  public references: { [key: string]: TemplateRef<any> } = {};
  @ViewChildren(TypeTemplateDirective) set _refs(refs: QueryList<TypeTemplateDirective>) {
    this.references = refs.reduce((obj: any, { appTemplateType: type, template }) => (obj[type] = template) && obj, {});
    this.changeDetector.detectChanges(); // To avoid ExpressionChangedAfterItHasBeenCheckedError
    this.active.emit(true);
  }

  constructor(
    public element: ElementRef,
    private entitiesService: EntitiesService,
    private formService: FormService,
    private changeDetector: ChangeDetectorRef,
    public loadingService: LoadingService,
  ) { }

  ngOnInit() {
    this.header = (this.breadcrumbs && this.breadcrumbs.length > 2) ? this.breadcrumbs.slice(-2, -1).join() : '';
    this.originalValue = this.control && this.control.value;
    this.subscriptions.set('ControlLoadingStatus', this.loadingService.loading$
      .subscribe(loading =>
        loading
          ? this.control && this.control.disable()
          : this.control && !(this.fieldSpec.system || this.fieldSpec.readonly) && this.control.enable())
    );

    if (this.fieldSpec && this.fieldSpec.ref) {
      if (this.control) {
        this.control.disable();
      } else {
        console.warn('FormCreator: Missing control for %s', this.key);
      }
      if (this.fieldSpec.ref.groupBy) {
        this.entitiesGrouped$ = this.getEntitiesGrouped(this.fieldSpec);
      } else {
        this.entities$ = this.getEntities(this.fieldSpec);
      }
    }
  }

  ngAfterContentChecked() {
    const el = this.element.nativeElement as HTMLElement;
    const collection = el.getElementsByClassName('mat-form-field');
    if (collection.length > 0) {
      const target = collection.item(0) as HTMLInputElement;
      if (this.control && this.control.invalid) {
        target.classList.add('mat-form-field-invalid');
      } else {
        target.classList.remove('mat-form-field-invalid');
      }
    }

    if (this.refValues$) {
      this.subscriptions.set('refValues', this.refValues$.subscribe(refs => {
        this.refValues = refs || [];
        if (Array.isArray(this.control?.value)) {
          if (refs && refs.some(ref => !this.control?.value.find((item: Entity) => item.id === ref.id))) {
            const value = Array.from(new Set([...refs, ...this.control.value]));
            this.control.patchValue(value, { emitEvent: false, onlySelf: true });
            this.entities$ = this.getEntities(this.fieldSpec);
          }
        }
      }));
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public reset(el: any) {
    const element = (el as HTMLElement);
    setTimeout(() => {
      if (element.blur) {
        element.blur();
      }
      this.control.setValue(this.originalValue);
      this.control.markAsPristine();
    }, 50);
  }

  getEntities({ ref: { to, compare, compareTo }, sortBy = 'name', validation }: FieldSpecification) {
    return combineLatest([
      this.refValues$,
      this.entitiesService.getAllByType(to)
    ])
      .pipe(
        // tap((x) => console.log('unfiltered entities', x)),
        map(([refs, entities]) => {
          return (refs && validation?.ref?.constraint === 'alwaysIncludes')
            ? entities.filter(entity => !refs.find(ref => ref.id === entity.id))
            : entities
        }),
        map(entities => entities
          .filter(entity => entity[compare] === compareTo)
          .sort((a, b) => {
            // TODO: extract to some kind of EntitySorter shared function
            if (sortBy) {
              const controlValue = this.control.value;
              const aSelected = !!controlValue
                ? Array.isArray(controlValue)
                  ? controlValue.some(item => item.id === a.id)
                  : controlValue.id === a.id
                : false;
              const bSelected = !!controlValue
                ? Array.isArray(controlValue)
                  ? controlValue.some(item => item.id === b.id)
                  : controlValue.id === b.id
                : false;
              const sortValueA = sortBy in a && a[sortBy].toLowerCase();
              const sortValueB = sortBy in b && b[sortBy].toLowerCase();
              if (aSelected && bSelected) {
                return sortValueA < sortValueB ? -1 : 1;
              }
              if (aSelected) {
                return -1;
              }
              if (bSelected) {
                return 1;
              }
              return sortValueA < sortValueB ? -1 : 1;
            }
            return a.id < b.id ? -1 : 1;
          })
        ),
      );
  }

  getEntitiesGrouped(fieldSpec: FieldSpecification) {

    const { ref: { groupBy } } = fieldSpec;
    return this.getEntities(fieldSpec)
      .pipe(
        map(
          entities => entities.reduce((list: Record<string, Entity[]>, entity: Entity) => {
            const header = entity[groupBy];
            list[header] = list[header] || [];
            list[header].push(entity);
            return list;
          }, {})
        )
      );
  }

  compareFn(c1: Entity, c2: Entity): boolean {
    return c1 && c1.id && c2 && c2.id ? c1.id === c2.id : c1 === c2;
  }

  equalityFn(a: any, b: any): boolean {
    return equality(a, b);
  }

  getAsDateObject(date: string): Date {
    return new Date(date);
  }

  // grab a nested form control for object types
  public getControl(path: string[], key: string, form: AbstractControl = this.form): AbstractControl {
    return form.get([...path, key]) as AbstractControl;
  }

  public getFormGroup(path: string[], form: AbstractControl = this.form): FormGroup {
    return form.get(path) as FormGroup;
  }

  public getFieldSpec(key: string) {
    return this.fieldSpec && this.fieldSpec.fields && this.fieldSpec.fields[key];
  }

  public getFormControls() {
    // TODO: as FormArray eller FormGroup
    return this.control && (this.control as FormArray).controls as FormControl[];
  }

  public getFormGroups() {
    // TODO: as FormArray eller FormGroup
    return this.control && (this.control as FormArray).controls as FormGroup[];
  }

  // Adds control to an FormArray Object;
  public addControl() {
    console.log('this.fieldSpec.fields', this.fieldSpec.fields);
    const newFormControl = this.fieldSpec.fields
      ? this.formService.createFormGroup(this.fieldSpec.fields, {}, this.multiple)
      : this.formService.createFormControl();
    this.control.enable();
    (this.control as FormArray).push(newFormControl);
    this.control.markAsDirty();
  }

  // Removes control and values to an FormArray Object;
  public removeControl(index: number) {
    (this.control as FormArray).removeAt(index);
    this.control.markAsDirty();
  }

  // private setLabel() {
  //   // this.label = [...(this.path || []), this.key].filter(Boolean).join('.')
  //   // this.label = this.path.join('.')
  //   console.log('this.label', this.label, 'bc', this.breadcrumbs, 'key', this.key, 'form', this.form, this.fieldSpec);
  // }

}
