import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { Entity, EntityEditorDialogData } from 'src/app/core/interfaces/entity.interface';
import { EntitiesService } from 'src/app/core/services/entities.service';
import { FormService } from '../../services/form.service';
import { EntityEditorComponent } from '../entity-editor/entity-editor.component';
import { WarningDialogComponent } from '../warning-dialog/warningdialog.component';

@Component({
  selector: 'app-entity-editor-dialog',
  templateUrl: './entity-editor-dialog.component.html',
  styleUrls: ['./entity-editor-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityEditorDialogComponent implements OnInit, OnDestroy {

  private subscriptions = new Map<string, Subscription>();
  // public form = this.formService.getForm(this.data);
  public form: FormGroup;

  affectedEntities: { first: string, additional: number };
  numEntities: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EntityEditorDialogData,
    private entitiesService: EntitiesService,
    private formService: FormService,
    private dialogRef: MatDialogRef<EntityEditorComponent>,
    public dialog: MatDialog,
    public readonly translate: TranslateService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.subscriptions.set('entity',
      // we start with an empty FormGroup matching this entity.
      this.data.entity$.subscribe(entity => {
        // add the actual data when it's ready.
        this.form = this.formService.createFormGroup(this.data.specification.fields, entity as Entity, this.data.multiple);
        this.changeDetectorRef.detectChanges();
      })
    );

    // this.affectedEntities = prettyPrintEntities(this.data.entities, 10);
    const max = 10;
    const entities = this.data.entities || [];
    this.affectedEntities = {
      first: entities.map(entity => entity.name || entity.id).slice(0, max).join(', '),
      additional: entities.length > max ? entities.length - max : 0
    };
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  close(data: boolean | {}): void {
    this.dialogRef.close(data);
  }

  private getDirtyValues(form: FormGroup) {
    const dirtyValues: Record<string, any> = {};
    Object.keys(form.controls).forEach(key => {
      const group = form.get(key) as FormGroup;
      if (group.dirty) {
        if (group.controls && Array.isArray(group.controls)) {
          // array
          dirtyValues[key] = group.value;
        } else if (group.controls) {
          // nested forms
          dirtyValues[key] = this.getDirtyValues(group);
        } else {
          // anything else
          dirtyValues[key] = group.value;
        }
      }
    });
    return dirtyValues;
  }

  public patchEntity() {
    if (this.data.id) {
      const patchedEntity = Object.assign({}, this.getDirtyValues(this.form));
      this.entitiesService.patch(this.data.specification, this.data.id, patchedEntity);
    } else {
      console.warn('EntityEditor: Tried to updateEntity() but has no id.', this.data);
    }
  }

  public patchEntities(): Observable<Entity[]> {
    if (!this.data.ids) {
      console.warn('EntityEditor: patchEntities() has no ids to patch.', this.data);
    }
    const ids = Array.from(new Set(this.data.ids)); // after save/close and then another patch, sometimes the selection contains dupes
    return this.entitiesService.patchMany(this.data.specification, ids, Object.assign({}, this.data.entity$, this.getDirtyValues(this.form)));
  }

  public createEntity(): Observable<Entity> {
    const objectToCreate = Object.assign({}, this.data.entity$, this.form.value);

    // The source and destination attributes break creation of a new entity as they contain cyclic references (very non-JSON-stringifiable)
    delete objectToCreate.source;
    delete objectToCreate.destination;

    return this.entitiesService.create(this.data.specification, objectToCreate);
  }

  // * Update entry after dialog popup validation
  public onSubmit(): void {
    if (this.data.create) {
      this.createEntity();
      this.close(true);
    } else {
      if (this.data.multiple) {
        this.sendWarning(this.close, this.patchEntities, 'action_warningTitle', 'action_editManyEntries');
      } else {
        this.patchEntity();
        this.close({ data: this.form });
      }
    }
    // Save/creates entry, exit confirm!
  }

  public sendWarning(closeFunc: (args: any) => void, func: () => Observable<unknown>, warningTitle: string, warningText: string) {
    const warningDialogRef = this.dialog.open(WarningDialogComponent, {
      data: {
        warningTitle,
        warningText
      }
    });
    warningDialogRef.afterClosed().subscribe((dialogResult: EntityEditorDialogData) => {
      // not very pretty
      if (dialogResult) {
        func.bind(this)().subscribe(() => {
          closeFunc.bind(this)(dialogResult);
        });
      }
    });
  }


}
