
export const equality = (a: any, b: any) => {
  return typeof a === typeof b && JSON.stringify(a) === JSON.stringify(b);
};

export const dataToCSV = (data: any, activeColumns: string[], delimiter: string) => {

  const replacerFunct = (key: string, value: any) =>
    value === null ? '' : value; // specify how you want to handle null values here

  let csv: any = data.map((row: any) =>
    activeColumns
      .map(field => {
        if (row[field] && row[field] instanceof Array) {
          console.log('problem data', row[field]);
          return row[field].map((post: any) => post.name || post.id || '').join(', ');
        } else if (row[field] && typeof (row[field]) === 'object') {
          return JSON.stringify(row[field], replacerFunct);
        } else {
          return JSON.stringify(row[field], replacerFunct);
        }
      })
      .join(delimiter)
  );
  csv.unshift(activeColumns.join(delimiter));
  csv = csv.join('\r\n');
  return csv;
};
