import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LoadingService } from '../../../../core/services/loading.service';
import { MetaResource, MetaResources } from '../../../interfaces/access.meta.interface';
import { AccessResource } from '../../../interfaces/access.resource.class';

@Component({
  selector: 'app-resource-list',
  templateUrl: './resource-list.component.html',
  styleUrls: ['./resource-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ResourceListComponent
    }
  ]
})
export class ResourceListComponent implements OnInit, ControlValueAccessor {

  @Input() meta: MetaResources;
  resources: AccessResource[] = [];
  newMeta?: MetaResource;

  // form control support
  touched = false;
  disabled = false;
  onChange = () => {};
  onTouched = () => {};

  constructor(
    public loadingService: LoadingService
  ) { }

  ngOnInit() {
  }

  // update
  public update(resource: AccessResource, index: number) {
    this.resources = this.resources = [...this.resources.slice(0, index), resource, ...this.resources.slice(index + 1)];
    this.onChange();
  }

  // add or remove actions
  public add(meta?: MetaResource) {
    if (!meta) {
      return;
    }
    const action = new AccessResource().setMeta(meta);
    this.resources.push(action);
    this.markAsTouched();
    this.onChange();
  }

  public duplicate(resource: AccessResource, index = -1) {
    const newAction = new AccessResource(resource.toString()).setMeta(resource.meta);
    if (index < 0) {
      this.resources.push(newAction);
    } else {
      this.resources = [...this.resources.slice(0, index), resource, ...this.resources.slice(index)];
    }
    this.markAsTouched();
    this.onChange();
  }

  public remove(index: number) {
    this.resources = [...this.resources.slice(0, index), ...this.resources.slice(index + 1)];
    this.markAsTouched();
    this.onChange();
  }

  // form control support
  writeValue(rawInput: string[]): void {
    this.resources = rawInput.map(x => new AccessResource(x).setMeta(this.meta));
    this.markAsTouched();
  }

  registerOnChange(fn: (values: string[]) => void): void {
    this.onChange = () => fn(this.resources.map(x => x.toString()));
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

}
