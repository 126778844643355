import { Column, Specification } from "../interfaces/specification.interface";
import { map, tap } from "rxjs/operators";

import { CachedRestService } from "../classes/cached.rest.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NotificationService } from "./notification.service";

@Injectable({
  providedIn: "root",
})
export class SpecificationService extends CachedRestService<Specification> {
  public specifications$ = this.items$.pipe(
    tap((specs) => {
      console.log(
        "SpecificationService: New specifications from backend.",
        specs
      );
      if (!specs.length) {
        this.notificationService.warn(
          `No data specifications. Your user account might not have sufficient access.`
        );
      }
    })
  );

  constructor(
    protected http: HttpClient,
    protected notificationService: NotificationService
  ) {
    super(
      http,
      notificationService,
      `/entities/v2/specifications/`, // default REST resource to use for the requests
      (input: Specification) => new Specification().deserialize(input) // function that creates a properly typed model from input data
    );
    this.cachedGetAll();
  }

  public get(name: string) {
    return this.items$.pipe(
      map((items) => items.find((item) => item.name === name))
    );
  }

  public columns(spec: Specification) {
    return spec
      ? [
          ...Object.entries(spec.fields)
            .filter(([_, data]) => !data.system && !data.skippable)
            .map(
              ([key, data]) =>
                ({
                  name: key,
                  key,
                  label: [spec.graphql.variable, key].join("."),
                  checked: !!data.primary,
                } as Column)
            ),
        ]
      : [];
  }

  public columns$(name: string) {
    return this.get(name).pipe(
      map((spec: Specification) => this.columns(spec))
    );
  }
}
